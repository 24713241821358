import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { adminAction } from '../../redux/common/action'
import { URL } from '../../redux/common/url'
import { AsyncPaginate } from 'react-select-async-paginate'

import DatePicker from 'react-datepicker'


import { toast } from 'react-toastify'
import moment from 'moment'
import axios from 'axios'


interface Option {
    value: string
    label: string
}

interface PaginatedResponse {
    results: Option[]
    has_more: boolean
}

interface Value {
    id: string;
    name: string;
}


function TripBook() {
    const [XEN, setXen] = useState<Value[]>([])
    const [JEN, setJen] = useState<Value[]>([])
    const [AEN, setAen] = useState<Value[]>([])
    const [ven, setVendor] = useState<Value[]>([])
    const [circles, setCircles] = useState<Value[]>([])

    const [managerKey, setManagerKey] = useState<any>(0)
    const [managerKeyAen, setManagerKeyAen] = useState<any>(0)
    const [managerKeyJen, setManagerKeyJen] = useState<any>(0)

    const [seleceteMangerXen, setseleceteMangerXen] = useState<any>({})
    const [seleceteMangerAen, setseleceteMangerAen] = useState<any>({})
    const [seleceteMangerJen, setseleceteMangerJen] = useState<any>({})
    const [seleceteMangerVendor, setseleceteMangerVendor] = useState<any>({})
    const [seleceteSource, setseleceteSource] = useState<any>({})
    const [criId, setcriId] = useState<any>('')
// 


    const loadOptionsgetManagerXen = async (search: any, loadedOptions: any, { page }: { page: any }) => {
        const token: any = await localStorage.getItem('kt-auth-react-v')

        // roledataUpdate

        const response = await fetch(
            URL.API_BASE_URL + `/api/admins/get_all_xen?search=${search}&page=${page}&circle_id=${criId}`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token).api_token}`,
                },
            }
        )

        const responseJSON: PaginatedResponse = await response.json()

        const options: any = responseJSON?.results?.map((data: any) => {
            data.value = data?.id
            data.label = data?.name

            return data
        })

        return {
            options: options,
            hasMore: responseJSON.has_more,
            additional: {
                page: page + 1,
            },
        }
    }
    const loadOptionsgetManagerAen = async (search: any, loadedOptions: any, { page }: { page: any }) => {
        const token: any = await localStorage.getItem('kt-auth-react-v')

        // roledataUpdate

        const response = await fetch(
            URL.API_BASE_URL +
            `/api/admins/get_department_child?search=${search}&page=${page}&id=${seleceteMangerXen?.id}&circle_id=${criId}`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token).api_token}`,
                },
            }
        )

        const responseJSON: PaginatedResponse = await response.json()

        const options: any = responseJSON?.results?.map((data: any) => {
            data.value = data?.id
            data.label = data?.name

            return data
        })

        return {
            options: options,
            hasMore: responseJSON.has_more,
            additional: {
                page: page + 1,
            },
        }
    }
    const loadOptionsgetManagerJen = async (search: any, loadedOptions: any, { page }: { page: any }) => {
        const token: any = await localStorage.getItem('kt-auth-react-v')

        // roledataUpdate

        const response = await fetch(
            URL.API_BASE_URL +
            `/api/admins/get_department_child?search=${search}&page=${page}&id=${seleceteMangerAen?.id}&circle_id=${criId}`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token).api_token}`,
                },
            }
        )

        const responseJSON: PaginatedResponse = await response.json()

        const options: any = responseJSON?.results?.map((data: any) => {
            data.value = data?.id
            data.label = data?.name

            return data
        })

        return {
            options: options,
            hasMore: responseJSON.has_more,
            additional: {
                page: page + 1,
            },
        }
    }

    const loadOptionsgetSource = async (search: any, loadedOptions: any, {page}: {page: any}) => {
        const token: any = await localStorage.getItem('kt-auth-react-v')
    
        // roledataUpdate

        const response = await fetch(
          URL.API_BASE_URL +  `/api/admins/getSource?search=${search}&page=${page}&jen_id=${seleceteMangerJen?.id}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(token).api_token}`,
            },
          }
        )
    
        const responseJSON: PaginatedResponse = await response.json()
    
        const options: any = responseJSON?.results?.map((data: any) => {
          data.value = data?.id
          data.label = data?.station_name
    
          return data
        })
    
        return {
          options: options,
          hasMore: responseJSON.has_more,
          additional: {
            page: page + 1,
          },
        }
      }
    


    useEffect(() => {
        console.log('sdfsdf')

        // Only load options if a role ID is selected
        if (criId) {
            // Call the loadOptionsRole function here
            loadOptionsgetManagerXen('', [], { page: 1 })
            loadOptionsgetManagerAen('', [], { page: 1 })
            loadOptionsgetManagerJen('', [], { page: 1 })
            setManagerKey((prevKey: any) => prevKey + 1)
            setManagerKeyAen((prevKey: any) => prevKey + 1)
            setManagerKeyJen((prevKey: any) => prevKey + 1)
            setseleceteMangerXen({})
            setseleceteMangerAen({})
            setseleceteMangerJen({})
        }
    }, [criId])


    const dispatch = useDispatch()

    const navigate = useNavigate()

    const userId = useParams()
    const [startDate, setStartDate] = useState(new Date())

    const get_users_details: any = useSelector((state: any) =>
        state.admin.get_users_details ? state.admin.get_users_details : {}
    )

    useEffect(() => {
        dispatch(adminAction.getuserdetails(userId?.id))
        return () => { }
    }, [])

    console.log(get_users_details)

    const [seleceteMangerVehicle, setseleceteMangerVehicle] = useState<any>({})
    const [seleceteMangerUser, setseleceteMangerUser] = useState<any>({})
    const [NewAddress, setNewAddress] = useState<any>('')
    const [destance, setdestance] = useState<any>('')
    const [bookingType, setbookingType] = useState<any>('NORMAL')



    const hendleTocreateBooking = () => {
        if (Object.keys(seleceteMangerJen).length == 0) {
            toast.error('please select Source ')
        } else if (Object.keys(seleceteMangerVehicle).length == 0) {
            toast.error('please select Vehivle ')
        } else if (Object.keys(seleceteMangerUser).length == 0) {
            toast.error('please select Destination  ')
        } else {
            dispatch(
                adminAction.create_booking(
                    {
                        user_id: userId?.id,
                        booking_type: bookingType,
                        source_hydrant_center: seleceteSource?.id,
                        vehicle: seleceteMangerVehicle?.vehicle_id,
                        destination: seleceteMangerUser?.id,
                        destination_new_name: NewAddress,
                        total_distance: destance,
                        // creater_id: seleceteMangerJen?.id,
                        // vendor_id: seleceteMangerVendor?.id,
                        date: moment(startDate).format('YYYY/MM/DD hh:mm A'),
                    },
                    navigate
                )
            )
        }
    }

    const fetchCircles = async () => {
        try {
            const response: any = await axios.get(`${URL.API_BASE_URL + URL.getAllCircles}`);
            if (response?.data?.data) {
                setCircles(response?.data?.data);
            }
        } catch (error) {
            console.log(error);
        }
    };


    //   const calculateDistance = () => {
    //     const startCoords = { latitude: 52.520008, longitude: 13.404954 };
    //     const endCoords = { latitude: 51.5074, longitude: -0.1278 };

    //     const distanceInMeters = geolib.getDistance(startCoords, endCoords);
    //     const distanceInKilometers = distanceInMeters / 1000;

    //     console.log(`Distance: ${distanceInKilometers} kilometers`);
    //   };
    const calculateDistance = () => {
        const startCoords = {
            latitude: seleceteSource?.latitude,
            longitude: seleceteSource?.longitude,
        }
        const endCoords = {
            latitude: seleceteMangerUser?.latitude,
            longitude: seleceteMangerUser?.longitude,
        }

        const R = 6371 // Radius of the Earth in kilometers
        const lat1 = toRadians(startCoords.latitude)
        const lon1 = toRadians(startCoords.longitude)
        const lat2 = toRadians(endCoords.latitude)
        const lon2 = toRadians(endCoords.longitude)

        const dLat = lat2 - lat1
        const dLon = lon2 - lon1

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        const distance = R * c

        setdestance(distance?.toFixed(1))

        console.log(`Distance: ${distance} kilometers`)
    }

    const toRadians = (degrees: any) => {
        return (degrees * Math.PI) / 180
    }

    useEffect(() => {
        if (Object.keys(seleceteMangerJen).length > 0 && Object.keys(seleceteMangerUser).length > 0) {
            calculateDistance()
        }
    }, [seleceteMangerJen])
    useEffect(() => {
        if (Object.keys(seleceteMangerJen).length > 0 && Object.keys(seleceteMangerUser).length > 0) {
            calculateDistance()
            setNewAddress(seleceteMangerUser?.address)
        }
    }, [seleceteMangerUser])

    const loadOptionsgetManagerVehicle = async (
        search: any,
        loadedOptions: any,
        { page }: { page: any }
    ) => {
        const token: any = await localStorage.getItem('kt-auth-react-v')

        const response = await fetch(
            URL.API_BASE_URL + `/api/admins/all_vendors_tanker?search=${search}&vendor_id=${seleceteMangerVendor?.id}&page=${page}`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token).api_token}`,
                },
            }
        )

        const responseJSON: PaginatedResponse = await response.json()

        console.log(responseJSON.results);
        

        const options: any = responseJSON?.results?.map((data: any) => {
            data.value = data?.vehicle_id
            data.label = data?.vehicle?.registration_number

            return data
        })

        return {
            options: options,
            hasMore: responseJSON.has_more,
            additional: {
                page: page + 1,
            },
        }
    }
    const loadOptionsgetManagerDestinations = async (
        search: any,
        loadedOptions: any,
        { page }: { page: any }
    ) => {
        const token: any = await localStorage.getItem('kt-auth-react-v')

        // roledataUpdate

        const response = await fetch(
            URL.API_BASE_URL +

            `/api/admins/user_destinations?search=${search}&page=${page}&user_id=${userId?.id}&circle_id=${criId}`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token).api_token}`,
                },
            }
        )

        const responseJSON: PaginatedResponse = await response.json()

        const options: any = responseJSON?.results?.map((data: any) => {
            data.value = data?.id
            data.label = data?.address

            return data
        })

        return {
            options: options,
            hasMore: responseJSON.has_more,
            additional: {
                page: page + 1,
            },
        }
    }

    const loadOptionsgetManagerVendor  = async (
        search: any,
        loadedOptions: any,
        { page }: { page: any }
    ) =>{

        const token: any = await localStorage.getItem('kt-auth-react-v')

        // roledataUpdate

        const response = await fetch(
            URL.API_BASE_URL +

            `/api/getAllVendor?circle_id=${criId}&page=${page}`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token).api_token}`,
                },
            }
        )
        
        const responseJSON: PaginatedResponse = await response.json()

      

        const options: any = responseJSON?.results?.map((data: any) => {
            data.value = data?.id
            data.label = data?.user_name

            return data
        })

        return {
            options: options,
            hasMore: responseJSON.has_more,
            additional: {
                page: page + 1,
            },
        }

    }


    const today = new Date()
    today.setHours(0, 0, 0, 0)

    // Set the maxDate to the next day
    const nextDay = new Date(today)
    nextDay.setDate(nextDay.getDate() + 1)

    const handleStartDateChange = (date: any) => {
        setStartDate(date)
    }

    useEffect(() => {
        fetchCircles()
    }, [])


    return (
        <div>
            <div className='d-flex flex-column flex-wrap justify-content-center me-3 page-title'>
                {/* <!--begin::Title-->  */}
                <h1 className='d-flex flex-column justify-content-center my-0 fw-bold text-dark page-heading fs-3'>
                    Booking
                </h1>
                {/* <!--end::Title-->  */}
                {/* <!--begin::Breadcrumb-->  */}
                <ul className='my-0 breadcrumb-separatorless pt-1 fw-semibold breadcrumb fs-7'>
                    {/* <!--begin::Item-->  */}
                    <li className='breadcrumb-item text-muted'>
                        {/* <a href='../../demo1/dist/index.html' className='text-hover-primary text-muted'>
                    Home
                  </a> */}
                        <Link to={'/'} className='text-hover-primary text-muted'>
                            Home
                        </Link>

                        {/* <Button variant='primary' onClick={() => setShowView(true)}>
                    Custom Width Modal
                  </Button> */}
                    </li>
                    {/* <!--end::Item-->  */}
                    {/* <!--begin::Item-->  */}
                    <li className='breadcrumb-item'>
                        <span className='bg-gray-400 w-5px h-2px bullet'></span>
                    </li>
                    {/* <!--end::Item-->  */}
                    {/* <!--begin::Item-->  */}
                    <li className='breadcrumb-item text-muted'> Booking</li>
                    {/* <!--end::Item-->  */}
                </ul>
                {/* <!--end::Breadcrumb-->  */}
            </div>

            <div id='kt_app_content' className='flex-column-fluid app-content'>
                {/* <!--begin::Content container-->  */}
                <div id='kt_app_content_container' className='app-container container-xxl'>
                    <div className='card card-flush'>
                        {/* <div className='mt-6 card-header'>
                            <div className='card-title'>
                                <div className='position-relative d-flex align-items-center my-1 me-5'></div>
                                <div className='position-relative d-flex align-items-center my-1 me-5'></div>
                            </div>
                        </div> */}

                        <div className='row'>
                            <div className='col-6'>
                                {' '}
                                <div className='p-5'>
                                    <input
                                        className='bg-transparent form-control'
                                        placeholder='Enter mobile number'
                                        readOnly
                                        value={get_users_details?.mobile}
                                    />
                                </div>
                            </div>
                            <div className='col-6'>
                                {' '}
                                <div className='p-5'>
                                    <input
                                        className='bg-transparent form-control'
                                        placeholder='Enter mobile number'
                                        readOnly
                                        value={get_users_details?.name}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id='kt_app_content' className='flex-column-fluid app-content'>
                {/* <!--begin::Content container-->  */}
                <div id='kt_app_content_container' className='app-container container-xxl'>
                    <div className='card card-flush'>
                        <div className='row'>
                            <div className='col-3'>
                                {' '}
                                <div className='p-5'>
                                    <label className='form-label mb-2 fw-semibold fs-6'>
                                        <span className='required'> Booking Type </span>
                                    </label>
                                    <select
                                        className='bg-transparent form-control'
                                        aria-label='Select example'
                                        name='circle_id'
                                        onChange={(e:any) => {
                                            setbookingType(e?.target?.value)
                                        }}
                                    >
                                        <option value={'NORMAL'}>NORMAL</option>
                                        <option value={'EMERGENCY'}>EMERGENCY</option>
                                    </select>
                                </div>
                            </div>

                            <div className='col-3'>
                                <div className='p-5'>
                                    <label className='form-label mb-2 fw-semibold fs-6'>
                                        <span className='required'>Pickup Date: </span>
                                    </label>

                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        minDate={today}
                                        maxDate={nextDay}
                                        showTimeSelect
                                        className='bg-transparent form-control'
                                        dateFormat='MMMM d, yyyy h:mm aa'
                                        placeholderText='Select today or next day'
                                    />
                                </div>
                            </div>



                            <div className='col-3'>
                                <div className='p-5'>
                                    <label className='form-label mb-2 fw-semibold fs-6'>
                                        <span className='required'>Circle</span>
                                    </label>
                                    <select
                                        className='bg-transparent form-control'
                                        aria-label='Select example'
                                        name='circle_id'
                                        onChange={(e:any) => {
                                            setcriId(e?.target?.value);
                                            setseleceteMangerXen({});
                                            setseleceteMangerAen({});
                                            setseleceteMangerJen({});
                                            setseleceteMangerVendor({});
                                            setseleceteMangerVehicle({});

                                            setseleceteSource({})

                                          }}
                                    >
                                        <option value={''}>Select Circle</option>
                                        {
                                            circles?.map((data, i) => {
                                                return (
                                                    <option value={data?.id} key={i}>
                                                        {data?.name}
                                                    </option>
                                                )

                                            })
                                        }

                                    </select>
                                </div>
                            </div>


                            <div className='col-3'>
                                <div className='p-5'>

                                    <label className='form-label mb-2 fw-semibold fs-6'>
                                        <span className='required'>Select Xen</span>
                                        <i
                                            className='ms-2 fas fa-exclamation-circle fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <AsyncPaginate<any, any, any>
                                        key={managerKey}
                                        value={seleceteMangerXen}
                                        loadOptions={loadOptionsgetManagerXen}
                                        onChange={(e:any) => {
                                            setseleceteMangerXen(e);
                                            setseleceteMangerAen({});
                                            setseleceteMangerJen({});
                                            setseleceteMangerVendor({});
                                            setseleceteMangerVehicle({});

                                            setseleceteSource({})

                                          }}
                                        additional={{
                                            page: 1,
                                        }}
                                        className='bg-transparent form-control'
                                        
                                    />
                                </div>
                            </div>


                            <div className='col-3'>
                                <div className='p-5'>
                                    <label className='form-label mb-2 fw-semibold fs-6'>
                                        <span className='required'>Select Aen</span>
                                        <i
                                            className='ms-2 fas fa-exclamation-circle fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <AsyncPaginate<any, any, any>
                                        key={managerKeyAen}
                                        value={seleceteMangerAen}
                                        loadOptions={loadOptionsgetManagerAen}
                                        onChange={(e:any) => {
                                            
                                            setseleceteMangerAen(e);
                                            setseleceteMangerJen({});
                                            setseleceteMangerVendor({});
                                            setseleceteMangerVehicle({});

                                            setseleceteSource({})

                                          }}
                                        additional={{
                                            page: 1,
                                        }}
                                        className='bg-transparent form-control'
                                    />
                                </div>
                            </div>


                            <div className='col-3'>
                                <div className='p-5'>
                                    <label className='form-label mb-2 fw-semibold fs-6'>
                                        <span className='required'>Select Jen</span>
                                        <i
                                            className='ms-2 fas fa-exclamation-circle fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <AsyncPaginate<any, any, any>
                                        key={managerKeyJen}
                                        value={seleceteMangerJen}
                                        loadOptions={loadOptionsgetManagerJen}
                                        onChange={(e:any) => {
                                            
                                            setseleceteMangerJen(e);
                                            setseleceteMangerVendor({});
                                            setseleceteSource({});
                                            setseleceteMangerVehicle({});


                                          }}
                                        additional={{
                                            page: 1,
                                        }}
                                        className='bg-transparent form-control'
                                    />
                                </div>
                            </div>

                            <div className='col-3'>
                                <div className='p-5'>
                                    <label className='form-label mb-2 fw-semibold fs-6'>
                                        <span className='required'>Vendor </span>
                                    </label>
                                    <AsyncPaginate<any, any, any>
                                        // key={managerKey}
                                        value={seleceteMangerVendor}
                                        loadOptions={loadOptionsgetManagerVendor}
                                        onChange={(e:any) => {
                                            
                                            setseleceteMangerVendor(e);
                                            setseleceteSource({});
                                            setseleceteMangerVehicle({});

                                          }}
                                        additional={{
                                            page: 1,
                                        }}
                                        className='bg-transparent form-control'
                                    />

                                </div>
                            </div>


                            
                            <div className='col-3'>
                                <div className='p-5'>
                                    <label className='form-label mb-2 fw-semibold fs-6'>
                                        <span className='required'>Vehicle </span>
                                    </label>
                                    <AsyncPaginate<any, any, any>
                                        // key={managerKey}
                                        value={seleceteMangerVehicle}
                                        loadOptions={loadOptionsgetManagerVehicle}
                                        onChange={(e:any) => {
                                            
                                            setseleceteSource({});
                                            setseleceteMangerVehicle(e);

                                          }}
                                        additional={{
                                            page: 1,
                                        }}
                                        className='bg-transparent form-control'
                                    />

                                </div>
                            </div>


                            <div className='col-3'>
                                <div className='p-5'>
                                    <label className='form-label mb-2 fw-semibold fs-6'>
                                        <span className='required'>Source </span>
                                    </label>

                                    <AsyncPaginate<any, any, any>
                                        // key={managerKey}
                                        value={seleceteSource}
                                        loadOptions={loadOptionsgetSource}
                                        onChange={setseleceteSource}
                                        additional={{
                                            page: 1,
                                        }}
                                        className='bg-transparent form-control'
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            <div id='kt_app_content' className='flex-column-fluid app-content'>
                {/* <!--begin::Content container-->  */}
                <div id='kt_app_content_container' className='app-container container-xxl'>
                    <div className='card card-flush'>
                        <div className='row'>
                            <div className='col-8'>
                                {' '}
                                <div className='p-5'>
                                    <label className='form-label mb-2 fw-semibold fs-6'>
                                        <span className='required'>Destination </span>
                                    </label>

                                    <AsyncPaginate<any, any, any>
                                        // key={managerKey}
                                        value={seleceteMangerUser}
                                        loadOptions={loadOptionsgetManagerDestinations}
                                        onChange={setseleceteMangerUser}
                                        additional={{
                                            page: 1,
                                        }}
                                        className='bg-transparent form-control'
                                    />
                                </div>
                            </div>
                            <div className='col-2'>
                                {' '}
                                <div className='p-5'>
                                    <label className='form-label mb-2 fw-semibold fs-6'>
                                        <span className='required'>latitude </span>
                                    </label>
                                    <input
                                        className='bg-transparent form-control'
                                        placeholder='Latitude'
                                        // onChange={(e:any) => {
                                        //   hendleTocheck(e.target.value)
                                        // }}
                                        readOnly
                                        value={seleceteMangerUser?.latitude}
                                    />
                                </div>
                            </div>
                            <div className='col-2'>
                                {' '}
                                <div className='p-5'>
                                    <label className='form-label mb-2 fw-semibold fs-6'>
                                        <span className='required'>longitude </span>
                                    </label>
                                    <input
                                        className='bg-transparent form-control'
                                        placeholder='Longitude'
                                        // onChange={(e:any) => {
                                        //   hendleTocheck(e.target.value)
                                        // }}
                                        readOnly
                                        value={seleceteMangerUser?.longitude}
                                    />
                                </div>
                            </div>
                            <div className='col-8'>
                                {' '}
                                <div className='p-5'>
                                    <label className='form-label mb-2 fw-semibold fs-6'>
                                        <span className='required'>Edit Destination Name </span>
                                    </label>
                                    <input
                                        className='bg-transparent form-control'
                                        placeholder='Destination'
                                        onChange={(e:any) => {
                                            setNewAddress(e.target.value)
                                        }}
                                        value={NewAddress}
                                    />
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='p-5'>
                                    <label className='form-label mb-2 fw-semibold fs-6'>
                                        <span className='required'>Total Distance: </span>
                                    </label>
                                    <input
                                        className='bg-transparent form-control'
                                        placeholder='Distance'
                                        // onChange={(e:any) => {
                                        //   hendleTocheck(e.target.value)
                                        // }}
                                        readOnly
                                        value={destance}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-4'>
                <div className='p-10'>
                    <button
                        onClick={() => {
                            hendleTocreateBooking()
                        }}
                        className='d-block btn btn-primary'
                    >
                        New Booking{' '}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default TripBook
