/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'

const ToolbarExtended: FC = () => {
  const [progress, setProgress] = useState<string>('1')
  const [search, setSearch] = useState<string>('')

  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
  }, [])

  return (
    <>
      <div className='d-flex flex-shrink-0 align-items-center me-5'>
        {/* begin::Label */}
        <span className='d-md-block pe-4 fw-bold text-gray-700 fs-7 d-none'>Team:</span>
        {/* end::Label */}

        {/* begin::Users */}
        <div className='symbol-group flex-shrink-0 me-2 symbol-hover'>
          {/* begin::User */}
          <div className='symbol symbol-circle symbol-35px'>
            <div className='bg-warning fw-bold text-inverse-warning symbol-label'>A</div>
          </div>
          {/* end::User */}

          {/* begin::User */}
          <div className='symbol symbol-circle symbol-35px'>
            <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
          </div>
          {/* end::User */}

          {/* begin::User */}
          <div className='symbol symbol-circle symbol-35px'>
            <img src={toAbsoluteUrl('/media/avatars/300-2.jpg')} alt='' />
          </div>
          {/* end::User */}

          {/* begin::User */}
          <div className='symbol symbol-circle symbol-35px'>
            <div className='bg-primary fw-bold text-inverse-primary symbol-label'>S</div>
          </div>
          {/* end::User */}

          {/* begin::User */}
          <div className='symbol symbol-circle symbol-35px'>
            <img src={toAbsoluteUrl('/media/avatars/300-5.jpg')} alt='' />
          </div>
          {/* end::User */}

          {/* begin::User */}
          <div className='symbol symbol-circle symbol-35px'>
            <div className='bg-danger fw-bold text-inverse-danger symbol-label'>P</div>
          </div>
          {/* end::User */}

          {/* begin::User */}
          <div className='symbol symbol-circle symbol-35px'>
            <img src={toAbsoluteUrl('/media/avatars/300-20.jpg')} alt='' />
          </div>
          {/* end::User */}
        </div>
        {/* end::Users */}

        {/* begin::Button */}
        <div
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Invite a team member'
        >
          <a href='#' className='btn btn-sm btn-icon'>
            <KTSVG
              path='/media/icons/duotune/general/gen035.svg'
              className='svg-icon-2hx svg-icon-success'
            />
          </a>
        </div>
      </div>
      {/* end::Button */}
      {/* end::Toolbar start */}

      {/* begin::Toolbar end */}
      <div className='d-flex align-items-center overflow-auto'>
        {/* begin::Search */}
        <div className='position-relative my-1'>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='top-50 position-absolute ps-10 translate-middle svg-icon-3 svg-icon-gray-500'
          />
          <input
            type='text'
            className='form-control form-control-sm form-control-solid ps-10 w-150px'
            name='Search Team'
            value={search}
            onChange={(e:any) => setSearch(e.target.value)}
            placeholder='Search Team'
          />
        </div>
        {/* end::Search */}

        {/* begin::Separartor */}
        <div className='bg-secondary mx-6 w-1px h-35px bullet'></div>
        {/* end::Separartor */}

        {/* begin::Label */}
        <span className='d-md-block flex-shrink-0 pe-4 fw-bold text-gray-700 fs-7 d-none'>
          Sort By:
        </span>
        {/* end::Label */}

        {/* begin::Select */}
        <select
          className='form-select me-6 w-125px form-select-sm form-select-solid'
          data-control='select2'
          data-placeholder='Latest'
          data-hide-search='true'
          value={progress}
          onChange={(e:any) => setProgress(e.target.value)}
        >
          <option value=''></option>
          <option value='1'>Latest</option>
          <option value='2'>In Progress</option>
          <option value='3'>Done</option>
        </select>
        {/* end::Select */}

        {/* begin::Actions */}
        <div className='d-flex align-items-center'>
          <button
            type='button'
            className='me-3 btn btn-sm btn-icon btn-light-primary'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='Enable grid view'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen025.svg'
              className='svg-icon-3 svg-icon-primary'
            />
          </button>

          <button
            type='button'
            className='btn btn-sm btn-icon btn-light'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='Enable row view'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen010.svg'
              className='svg-icon-3 svg-icon-gray-400'
            />
          </button>
        </div>
        {/* end::Actions */}
      </div>
    </>
  )
}

export {ToolbarExtended}
