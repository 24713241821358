/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../helpers'

const ToolbarAccounting: FC = () => {
  const [progress, setProgress] = useState<string>('1')
  const [filter, setFilter] = useState<string>('1')

  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
  }, [])

  return (
    <>
      <div className='d-flex align-items-center me-5'>
        {/* begin::Input group */}
        <div className='d-flex flex-shrink-0 align-items-center'>
          {/* begin::Label */}
          <span className='d-md-block pe-3 fw-bold text-gray-700 fs-7 d-none'>Actions:</span>
          {/* end::Label */}

          {/* begin::Actions */}
          <div className='d-flex flex-shrink-0'>
            {/* begin::Button */}
            <div
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Add a team member'
            >
              <a href='#' className='btn btn-sm btn-icon btn-active-color-success'>
                <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2x' />
              </a>
            </div>
            {/* end::Button */}

            {/* begin::Button */}
            <div
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Create new account'
            >
              <a href='#' className='btn btn-sm btn-icon btn-active-color-success'>
                <KTSVG path='/media/icons/duotune/general/gen037.svg' className='svg-icon-2x' />
              </a>
            </div>
            {/* end::Button */}

            {/* begin::Button */}
            <div
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Invite friends'
            >
              <a href='#' className='btn btn-sm btn-icon btn-active-color-success'>
                <KTSVG path='/media/icons/duotune/general/gen023.svg' className='svg-icon-2x' />
              </a>
            </div>
            {/* end::Button */}
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Input group */}

        {/* begin::Input group */}
        <div className='d-flex flex-shrink-0 align-items-center'>
          {/* begin::Desktop separartor */}
          <div className='bg-secondary mx-5 w-1px h-35px bullet'></div>
          {/* end::Desktop separartor */}

          {/* begin::Label */}
          <span className='d-md-block ps-1 pe-4 fw-bold text-gray-700 fs-7 d-none'>Progress:</span>
          {/* end::Label */}

          <div className='bg-light-success w-100px w-xxl-300px w-xl-150px h-25px progress'>
            <div
              className='bg-success rounded fw-bold progress-bar fs-7'
              role='progressbar'
              style={{width: '72%'}}
              aria-valuenow={72}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              72%
            </div>
          </div>
        </div>
        {/* end::Input group */}
        {/* end::Toolbar start */}
      </div>
      {/* begin::Toolbar end */}
      <div className='d-flex align-items-center'>
        {/* begin::Input group */}
        <div className='me-3'>
          {/* begin::Select */}
          <select
            className='form-select form-select-sm form-select-solid'
            data-control='select2'
            data-placeholder='Latest'
            data-hide-search='true'
            value={progress}
            onChange={(e:any) => setProgress(e.target.value)}
          >
            <option value=''></option>
            <option value='1'>Today 16 Feb</option>
            <option value='2'>In Progress</option>
            <option value='3'>Done</option>
          </select>
          {/* end::Select */}
        </div>
        {/* end::Input group- */}

        {/* begin::Input group- */}
        <div className='m-0'>
          {/* begin::Select */}
          <select
            className='form-select w-md-125px form-select-sm form-select-solid'
            data-control='select2'
            data-placeholder='Filters'
            data-hide-search='true'
            value={filter}
            onChange={(e:any) => setFilter(e.target.value)}
          >
            <option value=''></option>
            <option value='1'>Filters</option>
            <option value='2'>In Progress</option>
            <option value='3'>Done</option>
          </select>
          {/* end::Content */}
        </div>
        {/* end::Input group- */}
      </div>
    </>
  )
}

export {ToolbarAccounting}
