export const URL = {
  // API_BASE_URL: "https://phed.devhubtech.com",
  // API_BASE_URL: "http://192.168.0.13:8000",
  // test
  API_BASE_URL: "https://rrjtanker.com",

  RoutingURL: "https://staff.rrjtanker.com",
  // RoutingURL: "http://192.168.0.12:3006",

  revenueVendorwise: "/api/admins/revenueVendorwise",
  getReports: "/api/admins/getReports",
  create_booking: "/api/admins/create_booking",
  all_vendors_tanker: "/api/admins/all_vendors_tanker",
  repeatBooking: "/api/admins/repeatBooking",
  getAllBookings: "/api/admins/getAllBookings",
  dashboard: "/api/admins/dashboard",


  cheack_user: "/api/admins/cheack_user",
  getVendorList: "/api/getAllVendor",
  getUsersForLogin: "/getusersrollwise",

  getDiscussionList: "/api/discussions/getDiscussionsList",
  getDiscussion: "/api/discussions/getDiscussion",
  getdiscommentlist: "/api/discomment/getdiscommentlist",
  discommentAdd: "/api/discomment/add",
  getDisCategoryList: "/api/category/getCategoryList",
  createUser: "/api/users/add",
  login: "/api/signin",
  studentSignup: "/api/student/signup",
  studentVerfiyOtp: "/api/student/verfiyOtp",
  studentCheckEmailExist: "/api/student/checkEmailExist",
  studentCheckMobileExist: "/api/student/checkMobileExist",

  getAllUniversity: "/api/University/getAllUniversity",
  getuniversities: "/api/University/getuniversities",
  fineUniversityBySlug: "/api/university/fineUniversityBySlug",
  getrankinguniversities: "/api/university/getrankinguniversities",


  getDiscussionsbyId: "/api/discussions/getDiscussionsbyId",
  getUniversityById: "/api/University/getUniversityById",
  getAllUniversitiesComment: "/api/University/getAllUniversitiesComment",
  addUniversityComment: "/api/University/addUniversityComment",
  getAllUniCourses: "/api/UniversityCourse/getAllCourses",
  getAllCourses: "/api/Course/getAllCourses",


  getAllCounsellor: "/api/Counsellor/getAllCounsellor",
  getCounsellorInfo: "/api/counsellor/getCounsellorInfo",


  getCounsellorbyId: "/api/counsellor/getCounsellorbyId",
  getCounsellorBySlug: "/api/counsellor/getCounsellorBySlug",
  getcoachcounsellor: "/api/coach/getcoachcounsellor",



  getPermissionsList: "/api/admins/permission",
  rate: "/api/admins/rate",
  circle: "/api/admins/circle",
  update_user: "/api/admins/update_user",
  updateProfile: "/api/admins/updateProfile",
  update_user_destination: "/api/admins/update_user_destination",
  user: "/api/admins/user",
  deleteProfile: "/api/admins/deleteProfile",
  department: "/api/admins/department",
  userRoleWise: "/api/admins/getusersrollwise",
  update_filling_stations: "/api/admins/update_filling_stations",
  update_vehicle_driver: "/api/admins/update_vehicle_driver",
  update_vehicles_vendor: "/api/admins/update_vehicles_vendor",
  update_vehicle: "/api/admins/update_vehicle",

  getusersprofilerollwise: "/api/admins/getusersprofilerollwise",
  getAllCircles: "/api/admins/getAllCircles",
  getRoleList: "api/roles",
  createRoles: "/api/createRoles",
  createProfile: "/api/admins/createProfile",
  getAllProfile: "/api/admins/getAllProfile",


  assignUserProfile: "/api/admins/assignUserProfile",
  create_vehicle: "/api/admins/create_vehicle",
  all_vehicles: "/api/admins/all_vehicles",
  assignVehicle: "/api/admins/assignVehicle",
  add_vehicle_doc: "/api/admins/add_vehicle_doc",
  update_vehicle_doc: "/api/admins/update_vehicle_doc",
  vehicleDetail: "/api/admins/vehicleDetail",
  assign_vehicle_vendor: "/api/admins/assign_vehicle_vendor",
  vehicle_delete: "/api/admins/vehicle_delete",


  add_station: "/api/admins/add_station",
  all_filling_stations: "/api/admins/all_filling_stations",
  assign_manager_station: "/api/admins/assign_manager_station",
  fillingStationDetails: "/api/admins/fillingStationDetails",
  delete_filling_station: "/api/admins/delete_filling_station",
  documents: "/api/admins/documents",
  activeInactive: "/api/admins/activeInactive",
  documents_update: "/api/admins/documents_update",
  documentsApprove: "/api/admins/documentsApprove",
  user_destination: "/api/admins/user_destination",
  get_user_destination: "/api/admins/get_user_destination",
  delete_user_destination: "/api/admins/delete_user_destination",
  assign_driver: "/api/admins/assign_driver",
  all_vehicle_driver: "/api/admins/all_vehicle_driver",
  all_vehicle_vendor: "/api/admins/all_vehicles_vendor",
  deleteAssignDriver: "/api/admins/deleteAssignDriver",
  delete_vehicles_vendor: "/api/admins/delete_vehicles_vendor",
  createOtherService: "/api/admins/createOtherService",
  getAllOtherLinks: "/api/admins/getAllOtherLinks",
  updateOtherService: "/api/admins/updateOtherService",
  deleteOtherService: "/api/admins/deleteOtherService",
  createAppSettings: "/api/admins/createAppSettings",
  getAppSetings: "/api/admins/getAppSetings",
  updateAppSettings: "/api/admins/updateAppSettings",


  getCancelBookings: "/api/admins/getCancelBookings",
  getCurrentBookings: "/api/admins/getCurrentBookings",
  getCompleteBookings: "/api/admins/getCompleteBookings",
  updateBooking: "/api/admins/updateBooking",

  circleList: "/api/circle",
  roles: "/api/roles",

  updateBookingRemark: "/api/admins/updateBookingRemark",
  downloadReports: "/api/admins/downloadReports",
  downloadVendorwise: "/api/admins/downloadVendorwise",
};
