import { FC, useEffect, useState } from 'react'
import axios from 'axios'
import { URL } from '../../../redux/common/url'
import { toast } from 'react-toastify'
import { handleCopy, handleCSV, handleCSVNew, handleExcel, handleExcelNew, handlePDF, handlePDFNew } from './print'
import moment from 'moment'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { AsyncPaginate } from 'react-select-async-paginate'
import { reportColumns } from '../../constant'

interface Value {
  id: string
  name: string
};

interface Option {
  value: string
  label: string
};

interface PaginatedResponse {
  results: Option[]
  has_more: boolean
};

const VendorWise: FC = () => {
  const [vendor, setVendor] = useState<any[]>([])
  const [vehicle, setVehicle] = useState<any[]>([])
  const [circles, setCircles] = useState<Value[]>([])
  const [reportData, setReportData] = useState([])
  const [page, setPage] = useState<number>(1)
  const [vendorId, setVendorId] = useState<string>('')
  const [vehicleId, setVehicleId] = useState<string>('')
  const [fromDate, setFromDate] = useState<string>('')
  const [toDate, setToDate] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [circle_id, setCircle_id] = useState('')
  const [pageNo, setpageNo] = useState<any>(1)
  const [pageCount, setPageCount] = useState(0)

  const user = JSON.parse(localStorage.getItem('access_admin_token')!).data

  useEffect(() => {
    fetchCircles()
    getReports()
  }, [pageNo])
  const totalAmount = reportData?.reduce((acc: any, curr: any) => acc + curr.amount, 0)

  const fetchVehicles = async (vendorId: string) => {
    try {
      setVendorId(vendorId)
      const res = await axios.get(
        `${URL.API_BASE_URL}${URL.all_vendors_tanker}?vendor_id=${vendorId}&page=1`
      )
      if (res?.data?.results) {
        setVehicle(res?.data?.results)
      }
    } catch (error) {
      console.error(error)
    }
  };

  const handlePageClick = (event: any) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);
    const data = event?.selected + 1
    setpageNo(data)
    // if (!userSearch) {
    // dispatch(adminAction.getCurrentBookings(data))
    return () => { }
    // } else {
    //   dispatch(
    //     ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
    //   );

    //   return () => {};
    // };
  };

  const fetchCircles = async () => {
    try {
      const response = await axios.get(`${URL.API_BASE_URL + URL.getAllCircles}`)
      if (response?.data?.data) {
        setCircles(response?.data?.data)
      }
    } catch (error) {
      console.error(error)
    }
  };

  const fetchVendors = async (circleId: string) => {
    setCircle_id(circleId);
    // 
    // try {
    //   const res = await axios.get(`${URL.API_BASE_URL}${URL.getVendorList}?circle_id=${circleId}`)
    //   if (res?.data?.results) {
    //     setVendor(res?.data?.results)
    //   }
    // } catch (error) {
    //   console.error(error)
    // }
  };

  const getReports = async () => {
    try {
      const token = JSON.parse(localStorage.getItem('kt-auth-react-v')!)
      const config = {
        headers: {
          Authorization: 'Bearer ' + token.api_token,
        },
      }

      const query = [
        `approved=pending`,
        `page=${pageNo}`,
        vendorId && `vendor=${vendorId}`,
        vehicleId && `vehicle=${vehicleId}`,
        fromDate && `fromdate=${moment(fromDate).format('YYYY-MM-DD')}`,
        toDate && `todate=${moment(toDate).format('YYYY-MM-DD')}`,
        name && `search=${name}`,
      ]
        .filter(Boolean)
        .join('&')

      const res = await axios.get(`${URL.API_BASE_URL}${URL.getReports}?${query}`, config)

      if (res?.data?.data?.data) {
        setReportData(res?.data?.data?.data)
        // setPage(res?.data?.data?.current_page)
        setPageCount(Math.ceil(res?.data?.data?.total / 50))
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    }
  };

  const loadOptionsVendor = async (search: any, loadedOptions: any, { page }: { page: any, }) => {
    const token: any = await localStorage.getItem('kt-auth-react-v');

    let url = `${URL.getVendorList}?circle_id=${circle_id}`;

    const response = await fetch(`${URL.API_BASE_URL + url}&page=${page}&search=${search}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token).api_token}`,
        },
      }
    );
    const responseJSON: PaginatedResponse = await response.json();
    const options: any = responseJSON?.results?.map((data: any) => {
      data.value = data?.id;
      data.label = data?.name;
      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    }
  };

  const loadOptionsVehicle = async (search: any, loadedOptions: any, { page }: { page: any }) => {
    const token: any = await localStorage.getItem('kt-auth-react-v');

    let url = `${URL.all_vendors_tanker}?vendor_id=${vendorId}`;

    const response = await fetch(`${URL.API_BASE_URL + url}&page=${page}&search=${search}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token).api_token}`,
        },
      }
    );
    const responseJSON: PaginatedResponse = await response.json();
    const options: any = responseJSON?.results?.map((data: any) => {
      data.value = data?.vehicle_id;
      data.label = data?.vehicle?.registration_number;
      return data;
    });
    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    }
  };

  const handleDownload = async () => {
    try {
      const token: any = await localStorage.getItem('kt-auth-react-v')
      const config = {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(token).api_token,
        },
      }

      const query = [
        `approved=pending`,
        vendorId && `vendor=${vendorId}`,
        vehicleId && `vehicle=${vehicleId}`,
        fromDate && `fromdate=${moment(fromDate).format('YYYY-MM-DD')}`,
        toDate && `todate=${moment(toDate).format('YYYY-MM-DD')}`,
        name && `search=${name}`,
      ]
        .filter(Boolean)
        .join('&')

      const res = await axios.get(
        `${URL.API_BASE_URL}${URL.downloadReports}?${query}`, config);
      if (res?.data?.data) {
        return res?.data?.data;
      };
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    };
  };
  return (
    <div>
      <div className='d-flex justify-content-between'>
        <h3 className='text-gray-600'>Trip Report Vendor Wise</h3>
        <p>
          <span className='text-primary'>Home</span> / Trip Report Vendor Wise
        </p>
      </div>
      <div className='bg-transparent shadow m-0 p-3 pt-6 rounded-2xl'>
        <div className='d-flex justify-content-between px-5'>
          <div className='me-3' style={{ position: 'relative' }}>
            <label>
              <b>Circle</b>
            </label>
            <select
              className='form-control'
              aria-label='Select example'
              name='circle_id'
              onChange={(e:any) => fetchVendors(e.target.value)}
            >
              <option value=''>Select Circle</option>
              {circles.map((data, i) => (
                <option value={data.id} key={i}>
                  {data.name}
                </option>
              ))}
            </select>
          </div>
          <div className='me-3 col-2' style={{ position: 'relative' }}>
            <label>
              <b>Vendor</b>
            </label>{' '}
            <br />
            <AsyncPaginate<any, any, any>
              key={circle_id}
              // value={vendor_id}
              loadOptions={loadOptionsVendor}
              onChange={(item:any) => setVendorId(item?.value)}
              additional={{ page: 1 }}
              styles={{}}
              className='bg-transparent form-control p-0'
            />
          </div>

          <div className='me-3 col-2' style={{ position: 'relative' }}>
            <label>
              <b>Vehicle</b>
            </label>{' '}
            <br />
            <AsyncPaginate<any, any, any>
              key={vendorId}
              // value={vendor_id}
              loadOptions={loadOptionsVehicle}
              onChange={(e:any) => setVehicleId(e?.vehicle_id)}
              additional={{ page: 1 }}
              styles={{}}
              className='bg-transparent form-control p-0'
            />
          </div>

          <div className='me-3'>
            <label>
              <b>Date From:</b>
            </label>
            <input
              className='form-control'
              type='date'
              onChange={(e:any) => setFromDate(e.target.value)}
            />
          </div>
          <div className='me-3'>
            <label>
              <b>Date To:</b>
            </label>
            <input
              className='form-control'
              type='date'
              onChange={(e:any) => setToDate(e.target.value)}
            />
          </div>
          <div
            onClick={() => getReports()}
            className='mt-8'
            style={{
              backgroundColor: '#52A841',
              color: 'white',
              height: '30px',
              width: '250px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            View
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-between gap-4 mt-4 px-5'>
          <div className='d-flex gap-1'>
            {/* <div
              className='p-2'
              style={{ border: '1px solid #000', backgroundColor: '#ADAFAD', cursor: 'pointer' }}
              onClick={async () => {
                const data = await handleDownload();
                if (!data || !Array.isArray(data)) {
                  alert("No data available to copy");
                  return;
                }
                handleCopy(data);
              }}
            >
              Copy
            </div> */}
            <div
              className='p-2'
              style={{ border: '1px solid #000', backgroundColor: '#ADAFAD', cursor: 'pointer' }}
              onClick={async () => {
                const data = await handleDownload();
                handleCSVNew(data, reportColumns, "Report Vendor Wise", fromDate, toDate)
              }}
            >
              CSV
            </div>
            <div
              className='p-2'
              style={{ border: '1px solid #000', backgroundColor: '#ADAFAD', cursor: 'pointer' }}
              onClick={async () => {
                const data = await handleDownload();
                handlePDFNew(data, reportColumns, "Report Vendor Wise", fromDate, toDate)
              }}
            >
              PDF
            </div>
            <div
              className='p-2'
              style={{ border: '1px solid #000', backgroundColor: '#ADAFAD', cursor: 'pointer' }}
              onClick={async () => {
                const data = await handleDownload();
                handleExcelNew(data, reportColumns, "Report Vendor Wise", fromDate, toDate)
              }}
            >
              EXCEL
            </div>
          </div>

          {/* Search Field */}
          <div>
            <input
              type='search'
              placeholder='Search by Name'
              className='form-control'
              onChange={(e:any) => setName(e.target.value)}
            />
          </div>
        </div>

        {/* Pagination Table  */}
        <div className='mt-9'>
          <table className='table' style={{ fontSize: '12px', fontWeight: '300' }}>
            <thead className='table-light'>
              <tr>
                <th>S.NO.</th>
                <th>Job Id</th>
                <th>Pickup location</th>
                <th>Drop location</th>
                <th>Pickup Date / time</th>
                <th>End Date / time</th>
                <th>Trip Duration</th>
                <th>Name</th>
                <th>Number</th>
                <th>Tanker Number</th>
                <th>Distance</th>
                <th>Amount</th>
                <th>Remarks</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ maxHeight: '60vh', overflow: 'auto' }}>
              {reportData.length > 0 &&
                reportData.map((item: any, index: any) => (
                  <tr key={index}>
                    <td> {(pageNo - 1) * 50 + index + 1} </td>
                    <td>{item?.id}</td>
                    <td>{item?.source_hydrant_center?.address}</td>
                    <td>{item?.destination?.address}</td>
                    <td>{item?.accepted_time}</td>
                    <td>{item?.complete_time}</td>
                    <td>{item?.trip_duration}</td>
                    <td>{item?.user?.name}</td>
                    <td>{item?.user?.mobile}</td>
                    <td>{item?.vehicle?.registration_number}</td>
                    <td>{item?.total_distance}</td>
                    <td>{item?.amount}</td>
                    <td>{item?.remark}</td>
                    <td>
                      <Link to={`/view_details/${item?.id}/pending`}>
                        <button className='btn-group btn-group-sm bg-primary text-white'>
                          View Details
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>

            <tfoot>
              <tr>
                <td colSpan={6}></td>
                <td>
                  <b>Date</b>
                </td>
                <td> {fromDate ? fromDate : "--"} to{toDate ? toDate : "--"}</td>
                <td colSpan={2}></td>
                <td>
                  <b>Total</b>
                </td>
                <td colSpan={2} className='text-center fs-5'>
                  {totalAmount}
                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={14} style={{ textAlign: 'center' }}>
                  This is System Generated Report it does not require any signature or stamp.
                </td>
              </tr>
            </tfoot>
          </table>
          {/* <p>Showing 1 to 201 of 201 entries</p> */}
          <div style={{ height: '45px' }}>
            <ReactPaginate
              breakLabel='...'
              nextLabel='next'
              className='dataTables_paginate paging_simple_numbers category'
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              pageCount={pageCount}
              previousLabel='previous'
            // renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default VendorWise
