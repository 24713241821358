import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import { adminAction } from '../../../redux/common/action'
import { Pagination, TableHeader } from '../Table'
import PaginationComponent from '../Table/Pagination/Pagination'
import { useFormik } from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import Dropdown from 'react-bootstrap/Dropdown'
import { getAllbookingsAction } from '../../../redux/Admin/action'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'
import { URL } from '../../../redux/common/url'
import { KTSVG } from '../../../_metronic/helpers'
import moment from 'moment'

import DatePicker from 'react-datepicker'

import { AsyncPaginate } from 'react-select-async-paginate'

// @ts-ignore

// import {CKEditor} from '@ckeditor/ckeditor5-react'

// @ts-ignore
// import Editor from 'ckeditor5-custom-build/build/ckeditor'

// image uploader
import ImageUploading from 'react-images-uploading'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Loader } from '@googlemaps/js-api-loader'

const loginSchema = Yup.object().shape({
  role_name: Yup.string()
    // .role_name('Wrong role_name format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('product is required'),
  role_description: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('tegs is required'),
})

const initialValues = {
  role_name: '',
  role_description: '',
}

interface Option {
  value: string
  label: string
}

interface PaginatedResponse {
  results: Option[]
  has_more: boolean
}

interface Value {
  id: string
  name: string
}

function AllBookingList() {
  const [sortingField, setSortingField] = useState('')
  const [sortingOrder, setSortingOrder] = useState('asc')
  // const [comments, setComments] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(1)

  const [itemsPerPage, setitemsPerPage] = useState(10)

  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [sorting, setSorting] = useState({ field: '', order: '' })

  // deta table  state end

  // modal

  const [show, setShow] = useState(false)
  const [showassign, setShowassign] = useState(false)

  const [addpermission, setaddpermission] = useState(false)
  const [showDelelt, setShowDelete] = useState(false)
  const [bookingdata, setbookingdata] = useState<any>({})

  // date
  const [startDate, setStartDate] = useState(new Date())
  const [reg_cirtifiacte_expire_date, setreg_cirtifiacte_expire_date] = useState(new Date())
  const [puc_expire_date, setpuc_expire_date] = useState(new Date())
  const [insurance_expire_date, setinsurance_expire_date] = useState(new Date())

  const [reg_cirtifiacte_expire_date_up, setreg_cirtifiacte_expire_date_up] = useState(new Date())
  const [puc_expire_date_up, setpuc_expire_date_up] = useState(new Date())
  const [insurance_expire_date_up, setinsurance_expire_date_up] = useState(new Date())
  const [startDateup, setStartDateup] = useState(new Date())
  const [circles, setCircles] = useState<Value[]>([])
  const [cid, setCid] = useState<any>('')

  const [name, setName] = useState<string>('');


  const fetchCircles = async () => {
    try {
      const response: any = await axios.get(`${URL.API_BASE_URL + URL.getAllCircles}`)
      if (response?.data?.data) {
        setCircles(response?.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchCircles();
  }, [])

  const [showUpdate, setShowUpdate] = useState(false);
  const handleCloseUpdate = () => setShowUpdate(false);

  const [showUpdate1, setShowUpdate1] = useState(false) ;
  const handleCloseUpdate1 = () => setShowUpdate1(false);
  const [showUpdate2, setShowUpdate2] = useState(false);
  const handleCloseUpdate2 = () => setShowUpdate2(false);
  const [showUpdate3, setShowUpdate3] = useState(false);
  const handleCloseUpdate3 = () => setShowUpdate3(false);

  // modal end

  // api call

  const dispatch = useDispatch()
  const [roledata, setroledata] = useState<any>({
    role_name: '',
    role_description: '',
    meta_title: '',
    meta_description: '',
  })
  const [loading, setLoading] = useState(false)
  const [roleID, setroleID] = useState<any>('')
  const [productID, setproductID] = useState<any>('')
  const [pageNo, setpageNo] = useState<any>(1)
  const [selectedReason, setSelectedReason] = useState<any>(null);

  // get api data

  // image uploder

  const [images, setImages] = useState<any>([])
  const [imagespuc_photo, setImagespuc_photo] = useState<any>([])
  const [imagesinsurance_photo, setImagesinsurance_photo] = useState<any>([])
  const [imagesUpdate, setImagesUpdate] = useState<any>([])
  const [imagespuc_photoUpdate, setImagespuc_photoUpdate] = useState<any>([])
  const [imagesinsurance_photoUpdate, setImagesinsurance_photoUpdate] = useState<any>([])

  const cancelReasonRef = useRef<any>();
  const maxNumber = 1

  const [imagesss, setImagesss] = React.useState([])
  const maxNumberss = 8

  const get_product_list: any = useSelector((state: any) =>
    state.admin.get_all_booking ? state.admin.get_all_booking : {}
  )
  const ITEMS_PER_PAGE = 10

  const comments = get_product_list?.data ? get_product_list?.data : []

  const headers = [
    { name: 'S.No.', field: '_id', sortable: false },
    { name: 'Booking Id', field: 'id', sortable: false },
    { name: 'Customer Name', field: 'customer', sortable: false },
    { name: 'Number', field: 'number', sortable: false },
    { name: 'Otp', field: 'otp', sortable: false },
    { name: 'Vehicle', field: 'vehicle', sortable: false },
    { name: 'Booked By/Date Time', field: 'booked', sortable: false },
    { name: 'Pickup Location', field: 'location', sortable: false },
    { name: 'Drop Location', field: 'drop', sortable: false },
    { name: 'Pickup Date Time', field: 'time', sortable: false },
    { name: 'Reason', field: 'reason', sortable: false },
    { name: 'Driver', field: 'driver', sortable: false },
    { name: 'Driver status', field: 'driver_status', sortable: false },
    { name: 'Status', field: 'status', sortable: false },
  ]

  const commentsData = useMemo(() => {
    let computedComments = comments

    if (computedComments.length === 0) {
      return computedComments
    }

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1
      computedComments = computedComments.sort(
        (a: any, b: any) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      )
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    )
  }, [comments, currentPage, search, sorting])

  const handlePageClick = (event: any) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const data = event?.selected + 1

    setpageNo(data)

    // if (!userSearch) {
    // dispatch(adminAction.getCurrentBookings(data))

    return () => { }
    // } else {
    //   dispatch(
    //     ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
    //   );

    //   return () => {};
    // }
  }

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage

    setPageCount(Math.ceil(get_product_list?.total / itemsPerPage))
  }, [itemOffset, itemsPerPage, comments])

  // Table shoorting Function

  const onSortingChange = (field: any) => {
    const order = field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc'

    setSortingField(field)
    setSortingOrder(order)
    setSorting({ field, order })
  }

  const [managerKey, setManagerKey] = useState<any>(0)
  const [managerKeyAen, setManagerKeyAen] = useState<any>(0)
  const [managerKeyJen, setManagerKeyJen] = useState<any>(0)

  const [seleceteMangerXen, setseleceteMangerXen] = useState<any>({})
  const [seleceteMangerAen, setseleceteMangerAen] = useState<any>({})
  const [seleceteMangerJen, setseleceteMangerJen] = useState<any>({})
  const [selectsource, setselectsource] = useState<any>({})
  const [criId, setcriId] = useState<any>('')


  // Add this inside your component
  const calculateRouteDistance = () => {
    // if (!seleceteMangerJen?.latitude || !bookingdata?.destination?.longitudee) return;

    const loader = new Loader({
      apiKey: 'AIzaSyDxtE-9n6ddd01461k37m2Z9r-6SW4p0c8', // Replace with your actual API key
      version: 'weekly',
      libraries: ['routes']
    });

    loader.load()
      .then(() => {
        const directionsService = new (window as any).google.maps.DirectionsService();

        const origin = new (window as any).google.maps.LatLng(
          selectsource?.latitude,
          selectsource?.longitude
        );
        const destination = new (window as any).google.maps.LatLng(
          bookingdata?.destination?.latitude,
          bookingdata?.destination?.longitude
        );

        directionsService.route(
          {
            origin: origin,
            destination: destination,
            travelMode: (window as any).google.maps.TravelMode.DRIVING,
            optimizeWaypoints: true,
          },
          (response: any, status: any) => {
            if (status === 'OK' && response) {
              const distance = response.routes[0].legs[0].distance.value / 1000;
              setDistance(distance.toFixed(1));
            } else {
              console.error('Directions request failed due to ' + status);
              toast.error('Failed to calculate route distance: ' + status);
            }
          }
        );
      })
      .catch((error) => {
        console.error('Error loading Google Maps:', error);
        toast.error('Error loading Google Maps API. Please try again later.');
        //calculateHaversineDistance(); // Fallback
      });
  };

  useEffect(() => {
    if (Object.keys(selectsource).length > 0) {
      calculateRouteDistance();
    }
  }, [selectsource]);

  useEffect(() => {
    if (seleceteMangerJen?.id || criId || seleceteMangerXen?.id || seleceteMangerAen?.id) {
      // getCurrentBookingsForFilter
      const finaId = seleceteMangerJen?.id
        ? seleceteMangerJen?.id
        : seleceteMangerAen?.id
          ? seleceteMangerAen?.id
          : seleceteMangerXen?.id
            ? seleceteMangerXen?.id
            : criId
      const type = seleceteMangerJen?.id
        ? 'jen'
        : seleceteMangerAen?.id
          ? 'Aen'
          : seleceteMangerXen?.id
            ? 'Xen'
            : 'ca'

      dispatch(adminAction.getCurrentBookingsForFilter({ page: pageNo, id: finaId, type: type }))
    } else {
      dispatch(adminAction.getCurrentBookings2(pageNo, name))
    }
  }, [
    name,
    currentPage,
    pageNo,
    seleceteMangerJen?.id,
    criId,
    seleceteMangerXen?.id,
    seleceteMangerAen?.id,
  ]);

  useEffect(() => {
    dispatch(adminAction.getgetAllCircles(''))
    return () => { }
  }, []);

  const loadOptionsgetManagerXen = async (search: any, loadedOptions: any, { page }: { page: any }) => {
    const token: any = await localStorage.getItem('kt-auth-react-v')

    // roledataUpdate

    const response = await fetch(
      URL.API_BASE_URL + `/api/admins/get_all_xen?search=${search}&page=${page}&circle_id=${criId}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token).api_token}`,
        },
      }
    )

    const responseJSON: PaginatedResponse = await response.json()

    const options: any = responseJSON?.results?.map((data: any) => {
      data.value = data?.id
      data.label = data?.name

      return data
    })

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    }
  };

  const loadOptionsgetManagerAen = async (search: any, loadedOptions: any, { page }: { page: any }) => {
    const token: any = await localStorage.getItem('kt-auth-react-v')

    // roledataUpdate

    const response = await fetch(
      URL.API_BASE_URL +
      `/api/admins/get_department_child?search=${search}&page=${page}&id=${seleceteMangerXen?.id}&circle_id=${criId}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token).api_token}`,
        },
      }
    )

    const responseJSON: PaginatedResponse = await response.json()

    const options: any = responseJSON?.results?.map((data: any) => {
      data.value = data?.id
      data.label = data?.name

      return data
    })

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    }
  };

  const loadOptionsgetManagerJen = async (search: any, loadedOptions: any, { page }: { page: any }) => {
    const token: any = await localStorage.getItem('kt-auth-react-v')

    // roledataUpdate

    const response = await fetch(
      URL.API_BASE_URL +
      `/api/admins/get_department_child?search=${search}&page=${page}&id=${seleceteMangerAen?.id}&circle_id=${criId}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token).api_token}`,
        },
      }
    )

    const responseJSON: PaginatedResponse = await response.json()

    const options: any = responseJSON?.results?.map((data: any) => {
      data.value = data?.id
      data.label = data?.name

      return data
    })

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    }
  };

  const loadOptionsgetSource = async (search: any, loadedOptions: any, { page }: { page: any }) => {
    const token: any = await localStorage.getItem('kt-auth-react-v')

    // roledataUpdate

    const response = await fetch(
      URL.API_BASE_URL +
      `/api/admins/getSource?search=${search}&page=${page}&jen_id=${bookingdata?.user?.jen_id}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token).api_token}`,
        },
      }
    )

    const responseJSON: PaginatedResponse = await response.json()

    const options: any = responseJSON?.results?.map((data: any) => {
      data.value = data?.id
      data.label = data?.station_name

      return data
    })

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    }
  };

  // const [managerKey, setManagerKey] = useState<any>(0)
  // const [managerKeyAen, setManagerKeyAen] = useState<any>(0)
  // const [managerKeyJen, setManagerKeyJen] = useState<any>(0)

  useEffect(() => {
    // Only load options if a role ID is selected
    if (criId) {
      // Call the loadOptionsRole function here
      loadOptionsgetManagerXen('', [], { page: 1 })
      loadOptionsgetManagerAen('', [], { page: 1 })
      loadOptionsgetManagerJen('', [], { page: 1 })
      setManagerKey((prevKey: any) => prevKey + 1)
      setManagerKeyAen((prevKey: any) => prevKey + 1)
      setManagerKeyJen((prevKey: any) => prevKey + 1)
      setseleceteMangerXen({})
      setseleceteMangerAen({})
      setseleceteMangerJen({})
    }
  }, [criId])

  useEffect(() => {
    // Only load options if a role ID is selected
    if (seleceteMangerXen?.id) {
      // Call the loadOptionsRole function here
      // loadOptionsgetManagerXen('', [], {page: 1})
      loadOptionsgetManagerAen('', [], { page: 1 })
      loadOptionsgetManagerJen('', [], { page: 1 })
      // setManagerKey((prevKey: any) => prevKey + 1)
      setManagerKeyAen((prevKey: any) => prevKey + 1)
      setManagerKeyJen((prevKey: any) => prevKey + 1)
      // setseleceteMangerXen({})
      setseleceteMangerAen({})
      setseleceteMangerJen({})
    }
  }, [seleceteMangerXen])

  useEffect(() => {
    // Only load options if a role ID is selected
    if (seleceteMangerAen?.id) {
      // Call the loadOptionsRole function here
      // loadOptionsgetManagerXen('', [], {page: 1})
      // loadOptionsgetManagerAen('', [], {page: 1})
      loadOptionsgetManagerJen('', [], { page: 1 })
      // setManagerKey((prevKey: any) => prevKey + 1)
      // setManagerKeyAen((prevKey: any) => prevKey + 1)
      setManagerKeyJen((prevKey: any) => prevKey + 1)
      // setseleceteMangerXen({})
      // setseleceteMangerAen({})
      setseleceteMangerJen({})
    }
  }, [seleceteMangerAen])

  const [showView, setShowView] = useState(false)
  const [showViewDetails, setShowViewDetails] = useState<any>({})

  const hendleuserView = (e: any) => {
    setShowView(true)
    setShowViewDetails(e)
    // dispatch(adminAction.fillingStationDetails({id: e}))
  }

  // setShowassign

  const [vicle, setvicle] = useState('')
  const [cercle, setcercle] = useState('')

  const [seleceteMangerVehicle, setseleceteMangerVehicle] = useState<any>({})
  const [seleceteMangerDriver, setseleceteMangerDriver] = useState<any>({})
  const [distance, setDistance] = useState<any>('');
  const [bookingID, setbookingID] = useState<any>('');

  const hendelTocencil = (e: any) => {
    setbookingID(e)
    setShowUpdate3(true)
  };

  const hendletcancelBookin = () => {
    setShowUpdate3(false)
    // updateBooking

    const data = new FormData()

    data.append('booking_id', bookingID)
    // data.append('driver_id', seleceteMangerDriver?.id)
    // data.append('driver_status', 'Pending')
    data.append('status', 'Cancel')
    data.append('reject_reason', selectedReason ? selectedReason?.value : "Other");

    // data.append('total_distance', distance)
    var page = currentPage
    var id = seleceteMangerJen?.id

    dispatch(adminAction.updateBooking(data, 'cu', pageNo, id))
    setSelectedReason(null);

  };

  const chnageVehivleUp = (e: any) => {
    setbookingdata(e)
    if (e?.vehicle) {
      const vehilveData = e?.vehicle
      vehilveData.value = vehilveData?.id
      vehilveData.label = vehilveData?.registration_number
      setseleceteMangerVehicle(vehilveData)
    } else {
      setseleceteMangerVehicle({})
    }

    setShowUpdate(true)
  };

  const chnageSourceUp = (e: any) => {
    setbookingdata(e)
    if (e?.source_hydrant_center) {
      const vehilveData = e?.source_hydrant_center
      vehilveData.value = vehilveData?.id
      vehilveData.label = vehilveData?.address
    } else {
    }

    setShowUpdate1(true)
  }
  const chnageDriverUp = (e: any) => {
    setbookingdata(e)
    if (e?.driver) {
      const vehilveData = e?.driver
      vehilveData.value = vehilveData?.id
      vehilveData.label = vehilveData?.name
      setseleceteMangerDriver(vehilveData)
    } else {
      setseleceteMangerDriver({})
    }

    setShowUpdate2(true)
  };

  const colorPicker = (e: any) => {
    let color = 'white'

    switch (e) {
      case 'Pending':
        color = '#7ABAEB'
        break
      case 'Going-To-FillingStation':
        color = '#F4E778'
        break
      case 'Accepted':
        color = '#AFDDF4'
        break
      case 'Arrived-To-FillingStation':
        color = '#A995CD'
        break
      case 'Going-To-Destination':
        color = '#E8B86D'
        break
      case 'Arrived-To-Destination':
        color = '#A1D6B2'
        break
      case 'Filling-Started':
        color = '#BF2EF0'
        break
      case 'otp-pending':
        color = '#F6EACB'
        break
      case 'Completed':
        color = '#97BE5A'
        break

      case 'Cancel':
        color = '#FFAAAA'
        break
      default:
        color = 'white'
    }

    return color
  };

  const hendletochangeSource = () => {
    setShowUpdate1(false)
    // updateBooking

    const data = new FormData()

    data.append('booking_id', bookingdata?.id)
    data.append('source_hydrant_center', selectsource?.id)
    data.append('total_distance', distance)

    // dispatch(adminAction.updateBooking(data, pageNo, 'cu'))
  };

  const hendletochangeVehicle = () => {
    setShowUpdate(false)
    // updateBooking

    const data = new FormData()

    data.append('booking_id', bookingdata?.id)
    data.append('vehicle', seleceteMangerVehicle?.id)
    // data.append('total_distance', distance)

    dispatch(adminAction.updateBooking(data, 'cu', pageNo, 'test'))
  };

  const loadOptionsgetManagerDriver = async (
    search: any,
    loadedOptions: any,
    { page }: { page: any }
  ) => {
    const token: any = await localStorage.getItem('kt-auth-react-v')

    // &circle_id=${criId}
    // roledataUpdate
    const prfix = `/api/admins`
    const criId = `${JSON.parse(token)?.data?.circle_id}`
    const response = await fetch(
      URL.API_BASE_URL +
      prfix +
      `/get_all_driver_circle?search=${search}&page=${page}&circle_id=${bookingdata?.user?.circle_id}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token).api_token}`,
        },
      }
    )

    const responseJSON: PaginatedResponse = await response.json()

    const options: any = responseJSON?.results?.map((data: any) => {
      data.value = data?.id
      data.label = data?.name

      return data
    })

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    }
  };

  const loadOptionsgetManagerVehicle = async (
    search: any,
    loadedOptions: any,
    { page }: { page: any }
  ) => {
    const token: any = await localStorage.getItem('kt-auth-react-v')

    // roledataUpdate

    const prfix = `/api/admins`
    const jen_ID = JSON.parse(token)?.data?.id
    const response = await fetch(
      URL.API_BASE_URL +
      prfix +
      `/all_vehicle_jen?search=${search}&page=${page}&jen_id=${bookingdata?.user?.jen_id}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token).api_token}`,
        },
      }
    )

    const responseJSON: PaginatedResponse = await response.json()

    const options: any = responseJSON?.results?.map((data: any) => {
      data.value = data?.id
      data.label = data?.registration_number

      return data
    })

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    }
  };

  const hendletochangeDriver = () => {
    setShowUpdate2(false)
    // updateBooking

    const data = new FormData()

    data.append('booking_id', bookingdata?.id)
    data.append('driver_id', seleceteMangerDriver?.id)
    data.append('driver_status', 'Pending')
    data.append('status', 'Pending')

    // data.append('total_distance', distance)

    dispatch(adminAction.updateBooking(data, 'cu', pageNo, 'test'))
  };

  return (
    <div>
      <Modal show={showUpdate} dialogClassName='modal-90w' onHide={handleCloseUpdate}>
        <Modal.Header closeButton>
          <Modal.Title>Change Vehicle </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Woohoo, you're reading this text in a modal! */}
          {/* <form
                  id='kt_modal_update_permission_form'
                  className='form'
                  action='#'
                  onSubmit={formik.handleSubmit}
                  noValidate
                > */}

          <div className='row'>
            <div className='mb-7 fv-row col-6'>
              <div className='p-5'>
                <label className='form-label mb-2 fw-semibold fs-6'>
                  <span className='required'>Vehicle </span>
                </label>
                <AsyncPaginate<any, any, any>
                  // key={managerKey}
                  value={seleceteMangerVehicle}
                  loadOptions={loadOptionsgetManagerVehicle}
                  onChange={setseleceteMangerVehicle}
                  additional={{
                    page: 1,
                  }}
                />

                {/* <input
                    className='bg-transparent form-control'
                    placeholder='Enter mobile number'
                    // onChange={(e:any) => {
                    //   hendleTocheck(e.target.value)
                    // }}
                    value={get_users_details?.name}
                  /> */}
              </div>
            </div>
          </div>

          <div className='pt-15 text-center'>
            <button
              type='reset'
              className='me-3 btn btn-light'
              data-kt-permissions-modal-action='cancel'
              onClick={handleCloseUpdate}
            >
              Discard
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-permissions-modal-action='submit'
              onClick={() => {
                hendletochangeVehicle()
              }}
            // disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='ms-2 spinner-border spinner-border-sm align-middle'></span>
                </span>
              )}
            </button>
          </div>
          {/* </form> */}
        </Modal.Body>
        {/* <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Close
                </Button>
                <Button variant='primary' onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
      </Modal>

      <Modal show={showUpdate1} dialogClassName='modal-90w' onHide={handleCloseUpdate1}>
        <Modal.Header closeButton>
          <Modal.Title>Change source </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Woohoo, you're reading this text in a modal! */}
          {/* <form
                  id='kt_modal_update_permission_form'
                  className='form'
                  action='#'
                  onSubmit={formik.handleSubmit}
                  noValidate
                > */}

          <div className='row'>
            <div className='mb-7 fv-row col-6'>
              <div className='p-5'>
                <label className='form-label mb-2 fw-semibold fs-6'>
                  <span className='required'>Source </span>
                </label>

                <AsyncPaginate<any, any, any>
                  // key={managerKey}
                  value={selectsource}
                  loadOptions={loadOptionsgetSource}
                  onChange={setselectsource}
                  additional={{
                    page: 1,
                  }}
                />

                {/* <input
                    className='bg-transparent form-control'
                    placeholder='Enter mobile number'
                    // onChange={(e:any) => {
                    //   hendleTocheck(e.target.value)
                    // }}

                    value={get_users_details?.mobile}
                  /> */}
              </div>
            </div>
            <div className='mb-7 fv-row col-6'>
              <div className='p-5'>
                <label className='form-label mb-2 fw-semibold fs-6'>
                  <span className='required'>Distance </span>
                </label>

                <p>{distance && `${distance} km`}</p>

                {/* <input
                    className='bg-transparent form-control'
                    placeholder='Enter mobile number'
                    // onChange={(e:any) => {
                    //   hendleTocheck(e.target.value)
                    // }}

                    value={get_users_details?.mobile}
                  /> */}
              </div>
            </div>
          </div>

          <div className='pt-15 text-center'>
            <button
              type='reset'
              className='me-3 btn btn-light'
              data-kt-permissions-modal-action='cancel'
              onClick={handleCloseUpdate1}
            >
              Discard
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-permissions-modal-action='submit'
              onClick={() => {
                hendletochangeSource()
              }}
            // disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='ms-2 spinner-border spinner-border-sm align-middle'></span>
                </span>
              )}
            </button>
          </div>
          {/* </form> */}
        </Modal.Body>
        {/* <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Close
                </Button>
                <Button variant='primary' onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
      </Modal>

      <Modal show={showUpdate2} onHide={handleCloseUpdate2}>
        <Modal.Header closeButton>
          <Modal.Title>Change Driver </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Woohoo, you're reading this text in a modal! */}
          {/* <form
                  id='kt_modal_update_permission_form'
                  className='form'
                  action='#'
                  onSubmit={formik.handleSubmit}
                  noValidate
                > */}

          <div className='row'>
            <div className='mb-7 fv-row col-12'>
              <div className='p-5'>
                <label className='form-label mb-2 fw-semibold fs-6'>
                  <span className='required'>Select Driver </span>
                </label>

                <AsyncPaginate<any, any, any>
                  // key={managerKeyDriver}
                  value={seleceteMangerDriver}
                  loadOptions={loadOptionsgetManagerDriver}
                  onChange={setseleceteMangerDriver}
                  additional={{
                    page: 1,
                  }}
                />
              </div>
            </div>
          </div>

          <div className='pt-15 text-center'>
            <button
              type='reset'
              className='me-3 btn btn-light'
              data-kt-permissions-modal-action='cancel'
              onClick={handleCloseUpdate2}
            >
              Discard
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-permissions-modal-action='submit'
              onClick={() => {
                hendletochangeDriver()
              }}
            // disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='ms-2 spinner-border spinner-border-sm align-middle'></span>
                </span>
              )}
            </button>
          </div>
          {/* </form> */}
        </Modal.Body>
        {/* <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Close
                </Button>
                <Button variant='primary' onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
      </Modal>
      <Modal show={showUpdate3} onHide={handleCloseUpdate3}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Woohoo, you're reading this text in a modal! */}
          {/* <form
                  id='kt_modal_update_permission_form'
                  className='form'
                  action='#'
                  onSubmit={formik.handleSubmit}
                  noValidate
                > */}

          <div className='row'>
            <div className='mb-7 fv-row col-12'>
              <div className='p-5'>
                <label className='form-label mb-2 fw-semibold fs-6'>
                  <span className='required'>Are you sure you want to cancel this booking</span>
                </label>

                {/* <AsyncPaginate<any, any, any>
                  // key={managerKeyDriver}
                  value={seleceteMangerDriver}
                  loadOptions={loadOptionsgetManagerDriver}
                  onChange={setseleceteMangerDriver}
                  additional={{
                    page: 1,
                  }}
                /> */}
              </div>
            </div>
          </div>

          <div className='pt-15 text-center'>
            <button
              type='reset'
              className='me-3 btn btn-light'
              data-kt-permissions-modal-action='cancel'
              onClick={handleCloseUpdate3}
            >
              Discard
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-permissions-modal-action='submit'
              onClick={() => {
                hendletcancelBookin()
              }}
            // disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='ms-2 spinner-border spinner-border-sm align-middle'></span>
                </span>
              )}
            </button>
          </div>
          {/* </form> */}
        </Modal.Body>
        {/* <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Close
                </Button>
                <Button variant='primary' onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
      </Modal>
      {/* <div className='flex-row-fluid flex-column app-main' id='kt_app_main'> */}
      {/* <!--begin::Content wrapper-->  */}
      <div className='d-flex flex-column flex-column-fluid'>
        <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
          <div id='kt_app_toolbar_container' className='d-flex flex-stack container-xxl'>
            <div className='d-flex flex-column flex-wrap me-3 page-title'>
              <h1 className='my-0 fw-bold text-dark page-heading fs-3'>
                Current Job
              </h1>
              <ul className='my-0 breadcrumb-separatorless pt-1 fw-semibold breadcrumb fs-7'>
                <li className='breadcrumb-item text-muted'>
                  <Link to={'/'} className='text-hover-primary text-muted'>
                    Home
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <span className='bg-gray-400 w-5px h-2px bullet'></span>
                </li>
                <li className='breadcrumb-item text-muted'> Current Job</li>
              </ul>
            </div>
            <div className='d-flex align-items-center gap-2 gap-lg-3'>
              <div className='m-0'>
              </div>
            </div>
          </div>
        </div>

        <div id='kt_app_content' className='flex-column-fluid app-content'>
          <div id='' className=' '>
            {/* <!--begin::Card-->  */}
            <div className='card card-flush'>
              <div className='row'>
                <div className='col-3'>
                  <div className='p-5'>
                    <label className='form-label mb-2 fw-semibold fs-6'>
                      <span>Search</span>
                    </label>
                    <input
                      type='search'
                      placeholder='Search wise Name'
                      className='form-control'
                      onChange={(e: any) => setName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-3'>
                  <div className='p-5'>
                    <label className='form-label mb-2 fw-semibold fs-6'>
                      <span className='required'>Circle</span>
                    </label>
                    <select
                      className='bg-transparent form-control'
                      aria-label='Select example'
                      name='circle_id'
                      onChange={(e: any) => {
                        setcriId(e?.target?.value)
                        setseleceteMangerXen({})
                        setseleceteMangerAen({})
                        setseleceteMangerJen({})
                        setpageNo(1)
                      }}
                    >
                      <option value={''}>Select Circle</option>
                      {circles?.map((data, i) => {
                        return (
                          <option value={data?.id} key={i}>
                            {data?.name}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>

                <div className='col-3'>
                  <div className='p-5'>
                    <label className='form-label mb-2 fw-semibold fs-6'>
                      <span className='required'>Select Xen</span>
                      <i
                        className='ms-2 fas fa-exclamation-circle fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <AsyncPaginate<any, any, any>
                      key={managerKey}
                      value={seleceteMangerXen}
                      loadOptions={loadOptionsgetManagerXen}
                      onChange={(e: any) => {
                        setseleceteMangerXen(e)
                        setseleceteMangerAen({})
                        setseleceteMangerJen({})
                        setpageNo(1)
                      }}
                      additional={{
                        page: 1,
                      }}
                      className='bg-transparent form-control'
                    />
                  </div>
                </div>

                <div className='col-3'>
                  <div className='p-5'>
                    <label className='form-label mb-2 fw-semibold fs-6'>
                      <span className='required'>Select Aen</span>
                      <i
                        className='ms-2 fas fa-exclamation-circle fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <AsyncPaginate<any, any, any>
                      key={managerKeyAen}
                      value={seleceteMangerAen}
                      loadOptions={loadOptionsgetManagerAen}
                      onChange={(e: any) => {
                        setseleceteMangerAen(e)
                        setseleceteMangerJen({})
                        setpageNo(1)
                      }}
                      additional={{
                        page: 1,
                      }}
                      className='bg-transparent form-control'
                    />
                  </div>
                </div>

                <div className='col-3'>
                  <div className='p-5'>
                    <label className='form-label mb-2 fw-semibold fs-6'>
                      <span className='required'>Select Jen</span>
                      <i
                        className='ms-2 fas fa-exclamation-circle fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <AsyncPaginate<any, any, any>
                      key={managerKeyJen}
                      value={seleceteMangerJen}
                      loadOptions={loadOptionsgetManagerJen}
                      // onChange={setseleceteMangerJen}
                      onChange={(e: any) => {
                        // setseleceteMangerAen(e)
                        setseleceteMangerJen(e)
                        setpageNo(1)
                      }}
                      additional={{
                        page: 1,
                      }}
                      className='bg-transparent form-control'
                    />
                  </div>
                </div>
              </div>
              {/* <!--end::Card header-->  */}

              {/* <!--begin::Card body-->  */}
              <div className='pt-0 card-body' style={{ overflow: 'auto' }}>
                {/* <!--begin::Table-->  */}
                <table
                  className='table table-row-dashed mb-0 align-middle fs-6 gy-5'
                  id='kt_permissions_table'
                >
                  {/* <!--begin::Table head-->  */}
                  <thead>
                    <tr>
                      {headers.map((data: any, i: any) => (
                        <th
                          key={i}
                          onClick={() => (data?.sortable ? onSortingChange(data?.field) : null)}
                        >
                          {data?.name}

                          {sortingField &&
                            sortingField === data?.field &&
                            (sortingOrder === 'asc' ? (
                              //   <FontAwesomeIcon icon='fa-solid fa-arrow-down' />
                              <i className='fa-arrow-down fa-solid'></i>
                            ) : (
                              <i className='fa-arrow-alt-up fas'></i>
                            ))}
                        </th>
                      ))}
                    </tr>

                    {/* <!--end::Table row-->  */}
                  </thead>
                  {/* <!--end::Table head-->  */}
                  {/* <!--begin::Table body-->  */}
                  <tbody className='fw-semibold tbcolor'>
                    {commentsData?.map((data: any, i: any) => {
                      const So = 1 + i
                      const pgt1 = pageNo - 1
                      const pgt = pgt1 * 10
                      const soNo = pgt + So

                      return (
                        <tr key={i} style={{ backgroundColor: `${colorPicker(data?.status)}` }}>
                          <td>
                            {soNo}
                          </td>
                          <td>{data?.id}</td>
                          <td>{data?.user?.name}</td>
                          <td>{data?.user?.mobile}</td>
                          <td style={{ color: 'red' }}>{data?.otp}</td>
                          <td>
                            {data?.vehicle?.registration_number}
                            <button
                              data-id='2209'
                              onClick={() => {
                                chnageVehivleUp(data)
                              }}
                              className='ms-3 btn btn-sm btn-info2'
                              style={{
                                cursor: 'pointer',
                                backgroundColor: 'blue',
                                border: 'none',
                                color: 'white',
                                borderRadius: '5px',
                                padding: '5px 10px',
                                fontSize: '10px',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                              }}
                            >
                              change
                              {/* <i className='fa-trash-alt fas'></i> */}
                            </button>
                          </td>
                          <td>{moment(data?.date).format('DD-MM-yyyy hh:mm:ss A')}</td>
                          <td>
                            {data?.source_hydrant_center?.address}

                            <button
                              data-id='2209'
                              onClick={() => {
                                chnageSourceUp(data)
                              }}
                              className='ms-3 btn btn-sm btn-info2'
                              style={{
                                cursor: 'pointer',
                                backgroundColor: 'blue',
                                border: 'none',
                                color: 'white',
                                borderRadius: '5px',
                                padding: '5px 10px',
                                fontSize: '10px',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                              }}
                            >
                              change
                              {/* <i className='fa-trash-alt fas'></i> */}
                            </button>
                          </td>
                          <td>{data?.destination?.address}</td>
                          <td>{data?.accepted_time
                            ? moment(data?.accepted_time).format('DD-MM-yyyy hh:mm:ss A')
                            : "--"}
                          </td>
                          <td>
                            {data?.reject_reason}

                            <select className='form-controltest'
                              // ref={cancelReasonRef}
                              // key={selectedReason}
                              value={selectedReason?.id === data?.id ? selectedReason?.value : ""}
                              onChange={(e: any) => {
                                setSelectedReason({ id: data?.id, value: e.target.value })
                              }
                              }
                            >
                              <option value={'Select Cancel reason'}>Select Cancel reason</option>
                              <option value={'Vehicle Report Late'}>Vehicle Report Late</option>
                              <option value={'Vehicle Not Available'}>Vehicle Not Available</option>
                              <option value={'Cancel By Customer'}>Cancel By Customer</option>
                              <option value={'Vehicle Breakdown'}>Vehicle Breakdown</option>
                              <option value={'Repeat Booking'}>Repeat Booking</option>
                              <option value={'Booking Reschedule'}> Booking Reschedule</option>
                              <option value={'Other'}> Other</option>
                            </select>
                          </td>
                          <td>
                            {data?.driver?.name}
                            <button
                              data-id='2209'
                              onClick={() => {
                                chnageDriverUp(data)
                              }}
                              style={{
                                cursor: 'pointer',
                                backgroundColor: 'blue',
                                border: 'none',
                                color: 'white',
                                borderRadius: '5px',
                                padding: '5px 10px',
                                fontSize: '10px',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                              }}
                              className='ms-3 btn btn-sm btn-info2'
                            >
                              change
                              {/* <i className='fa-trash-alt fas'></i> */}
                            </button>
                          </td>
                          <td>
                            <p>{data?.driver_status}</p>
                          </td>
                          <td>
                            <p>{data?.status}</p>
                            <button
                              data-id='2209'
                              onClick={() => {
                                hendelTocencil(data?.id)
                              }}
                              className='ms-3 btn btn-sm btn-info2'
                              style={{
                                cursor: 'pointer',
                                backgroundColor: 'blue',
                                border: 'none',
                                color: 'white',
                                borderRadius: '5px',
                                padding: '5px 10px',
                                fontSize: '10px',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                              }}
                            >
                              Cancel
                              {/* <i className='fa-trash-alt fas'></i> */}
                            </button>
                            {/* <button
                              data-id='2209'
                              onClick={() => {
                                hendletcancelBookin(data)
                              }}
                              className='ms-3 btn btn-sm btn-info2'
                              style={{
                                cursor: 'pointer',
                                backgroundColor: 'blue',
                                border: 'none',
                                color: 'white',
                                borderRadius: '5px',
                                padding: '5px 10px',
                                fontSize: '10px',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                              }}
                            >
                              Cancel
                              
                            </button> */}
                            {/* <button
                              data-id='2209'
                              onClick={() => {
                                hendleuserView(data)
                              }}
                              className='ms-3 btn btn-sm btn-info'
                            >
                              <i className='fas fa-eye'></i>
                            </button>
                            <button
                              data-id='2209'
                              onClick={() => {
                                hendleModalShow(data?.id)
                              }}
                              className='ms-3 btn btn-sm btn-danger'
                            >
                              <i className='fa-trash-alt fas'></i>
                            </button> */}
                          </td>
                          {/* <!--end::Action=-->  */}
                        </tr>
                      )
                    })}
                  </tbody>
                  {get_product_list?.totalDocs == '0' && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '400px',
                      }}
                    >
                      <h3>Record Not found</h3>
                    </div>
                  )}

                  {/* <!--end::Table body-->  */}
                </table>
                {/* <div className='col-md-6'> */}
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='next'
                  className='dataTables_paginate paging_simple_numbers category'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  pageCount={pageCount}
                  previousLabel='previous'
                // renderOnZeroPageCount={null}
                />
                {/* </div> */}
                {/* <!--end::Table-->  */}
              </div>
            </div>

            {/* <!--begin::Modal - Add permissions-->  */}
            <div
              className='modal fade'
              id='kt_modal_add_permission'
              tabIndex={-1}
              aria-hidden='true'
            >
              {/* <!--begin::Modal dialog-->  */}
              <div className='mw-650px modal-dialog modal-dialog-centered'>
                {/* <!--begin::Modal content-->  */}
                <div className='modal-content'>
                  {/* <!--begin::Modal header-->  */}
                  <div className='modal-header'>
                    {/* <!--begin::Modal title-->  */}
                    <h2 className='fw-bold'>Add a Permission</h2>
                    {/* <!--end::Modal title-->  */}
                    {/* <!--begin::Close-->  */}
                    <div
                      className='btn btn-icon btn-sm btn-active-icon-primary'
                      data-kt-permissions-modal-action='close'
                    >
                      {/* <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->  */}
                      <span className='svg-icon svg-icon-1'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.5'
                            x='6'
                            y='17.3137'
                            width='16'
                            height='2'
                            rx='1'
                            transform='rotate(-45 6 17.3137)'
                            fill='currentColor'
                          />
                          <rect
                            x='7.41422'
                            y='6'
                            width='16'
                            height='2'
                            rx='1'
                            transform='rotate(45 7.41422 6)'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                      {/* <!--end::Svg Icon-->  */}
                    </div>
                    {/* <!--end::Close-->  */}
                  </div>
                  {/* <!--end::Modal header-->  */}
                  {/* <!--begin::Modal body-->  */}
                  <div className='mx-5 mx-xl-15 my-7 scroll-y modal-body'>
                    {/* <!--begin::Form-->  */}
                    <form id='kt_modal_add_permission_form' className='form' action='#'>
                      {/* <!--begin::Input group-->  */}
                      <div className='mb-7 fv-row'>
                        {/* <!--begin::Label-->  */}
                        <label className='form-label mb-2 fw-semibold fs-6'>
                          <span className='required'>Permission Name</span>
                          <i
                            className='ms-2 fas fa-exclamation-circle fs-7'
                            data-bs-toggle='popover'
                            data-bs-trigger='hover'
                            data-bs-html='true'
                            data-bs-content='Permission names is required to be unique.'
                          ></i>
                        </label>
                        {/* <!--end::Label-->  */}
                        {/* <!--begin::Input-->  */}
                        <input
                          className='form-control form-control-solid'
                          placeholder='Enter a permission name'
                          name='permission_name'
                        />
                        {/* <!--end::Input-->  */}
                      </div>
                      {/* <!--end::Input group-->  */}
                      {/* <!--begin::Input group-->  */}
                      <div className='mb-7 fv-row'>
                        {/* <!--begin::Checkbox-->  */}
                        <label className='form-check form-check-custom form-check-solid me-9'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            name='permissions_core'
                            id='kt_permissions_core'
                          />
                          <span className='form-check-label' /* for='kt_permissions_core' */>
                            Set as core permission
                          </span>
                        </label>
                        {/* <!--end::Checkbox-->  */}
                      </div>
                      {/* <!--end::Input group-->  */}
                      {/* <!--begin::Disclaimer-->  */}
                      <div className='text-gray-600'>
                        Permission set as a<strong className='me-1'>Core Permission</strong>will be
                        locked and
                        <strong className='me-1'>not editable</strong>in future
                      </div>
                      {/* <!--end::Disclaimer-->  */}
                      {/* <!--begin::Actions-->  */}
                      <div className='pt-15 text-center'>
                        <button
                          type='reset'
                          className='me-3 btn btn-light'
                          data-kt-permissions-modal-action='cancel'
                        >
                          Discard
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary'
                          data-kt-permissions-modal-action='submit'
                        >
                          <span className='indicator-label'>Submit</span>
                          <span className='indicator-progress'>
                            Please wait...
                            <span className='ms-2 spinner-border spinner-border-sm align-middle'></span>
                          </span>
                        </button>
                      </div>
                      {/* <!--end::Actions-->  */}
                    </form>
                    {/* <!--end::Form-->  */}
                  </div>
                  {/* <!--end::Modal body-->  */}
                </div>
                {/* <!--end::Modal content-->  */}
              </div>
              {/* <!--end::Modal dialog-->  */}
            </div>

            {/* <!--begin::Modal - Update permissions-->  */}
            <div
              className='modal fade'
              id='kt_modal_update_permission'
              tabIndex={-1}
              aria-hidden='true'
            >
              {/* <!--begin::Modal dialog-->  */}
              <div className='mw-650px modal-dialog modal-dialog-centered'>
                {/* <!--begin::Modal content-->  */}
                <div className='modal-content'>
                  {/* <!--begin::Modal header-->  */}
                  <div className='modal-header'>
                    {/* <!--begin::Modal title-->  */}
                    <h2 className='fw-bold'>Update Permission</h2>
                    {/* <!--end::Modal title-->  */}
                    {/* <!--begin::Close-->  */}
                    <div
                      className='btn btn-icon btn-sm btn-active-icon-primary'
                      data-kt-permissions-modal-action='close'
                    >
                      {/* <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->  */}
                      <span className='svg-icon svg-icon-1'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.5'
                            x='6'
                            y='17.3137'
                            width='16'
                            height='2'
                            rx='1'
                            transform='rotate(-45 6 17.3137)'
                            fill='currentColor'
                          />
                          <rect
                            x='7.41422'
                            y='6'
                            width='16'
                            height='2'
                            rx='1'
                            transform='rotate(45 7.41422 6)'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                      {/* <!--end::Svg Icon-->  */}
                    </div>
                    {/* <!--end::Close-->  */}
                  </div>
                  {/* <!--end::Modal header-->  */}
                  {/* <!--begin::Modal body-->  */}
                  <div className='mx-5 mx-xl-15 my-7 scroll-y modal-body'>
                    {/* <!--begin::Notice-->  */}
                    {/* <!--begin::Notice-->  */}
                    <div className='d-flex bg-light-warning mb-9 p-6 border border-warning border-dashed rounded notice'>
                      {/* <!--begin::Icon-->  */}
                      {/* <!--begin::Svg Icon | path: icons/duotune/general/gen044.svg-->  */}
                      <span className='me-4 svg-icon svg-icon-2tx svg-icon-warning'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.3'
                            x='2'
                            y='2'
                            width='20'
                            height='20'
                            rx='10'
                            fill='currentColor'
                          />
                          <rect
                            x='11'
                            y='14'
                            width='7'
                            height='2'
                            rx='1'
                            transform='rotate(-90 11 14)'
                            fill='currentColor'
                          />
                          <rect
                            x='11'
                            y='17'
                            width='2'
                            height='2'
                            rx='1'
                            transform='rotate(-90 11 17)'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                      {/* <!--end::Svg Icon-->  */}
                      {/* <!--end::Icon-->  */}
                      {/* <!--begin::Wrapper-->  */}
                      <div className='d-flex flex-grow-1 flex-stack'>
                        {/* <!--begin::Content-->  */}
                        <div className='fw-semibold'>
                          <div className='text-gray-700 fs-6'>
                            <strong className='me-1'>Warning!</strong>By editing the permission
                            name, you might break the system permissions functionality. Please
                            ensure you're absolutely certain before proceeding.
                          </div>
                        </div>
                        {/* <!--end::Content-->  */}
                      </div>
                      {/* <!--end::Wrapper-->  */}
                    </div>
                    {/* <!--end::Notice-->  */}
                    {/* <!--end::Notice-->  */}
                    {/* <!--begin::Form-->  */}
                    <form id='kt_modal_update_permission_form' className='form' action='#'>
                      {/* <!--begin::Input group-->  */}
                      <div className='mb-7 fv-row'>
                        {/* <!--begin::Label-->  */}
                        <label className='form-label mb-2 fw-semibold fs-6'>
                          <span className='required'>Permission Name</span>
                          <i
                            className='ms-2 fas fa-exclamation-circle fs-7'
                            data-bs-toggle='popover'
                            data-bs-trigger='hover'
                            data-bs-html='true'
                            data-bs-content='Permission names is required to be unique.'
                          ></i>
                        </label>
                        {/* <!--end::Label-->  */}
                        {/* <!--begin::Input-->  */}
                        <input
                          className='form-control form-control-solid'
                          placeholder='Enter a permission name'
                          name='permission_name'
                        />
                        {/* <!--end::Input-->  */}
                      </div>
                      {/* <!--end::Input group-->  */}
                      {/* <!--begin::Actions-->  */}
                      <div className='pt-15 text-center'>
                        <button
                          type='reset'
                          className='me-3 btn btn-light'
                          data-kt-permissions-modal-action='cancel'
                        >
                          Discard
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary'
                          data-kt-permissions-modal-action='submit'
                        >
                          <span className='indicator-label'>Submit</span>
                          <span className='indicator-progress'>
                            Please wait...
                            <span className='ms-2 spinner-border spinner-border-sm align-middle'></span>
                          </span>
                        </button>
                      </div>
                      {/* <!--end::Actions-->  */}
                    </form>
                    {/* <!--end::Form-->  */}
                  </div>
                  {/* <!--end::Modal body-->  */}
                </div>
                {/* <!--end::Modal content-->  */}
              </div>
              {/* <!--end::Modal dialog-->  */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllBookingList
