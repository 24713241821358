import { FC, useEffect, useState } from "react";
import axios from "axios";
import { URL } from '../../../redux/common/url';
import { toast } from "react-toastify";
import { handleCopy, handleCSV, handleExcel, handlePDF } from '../Provisional/print';
import moment from 'moment';
import { handleCSVNew, handlePDFNew, handleExcelNew } from '../Provisional/print';
import { Link } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { reportColumns, revenueReportColumns } from "../../constant";
import ReactPaginate from "react-paginate";


interface Value {
  id: string;
  name: string;
};

interface Option {
  value: string
  label: string
};

interface PaginatedResponse {
  results: Option[]
  has_more: boolean
};

const Vef_Revenue: FC = () => {
  const [vendor, setVendor] = useState<any[]>([]);
  const [vehicle, setVehicle] = useState<any[]>([]);
  const [circles, setCircles] = useState<Value[]>([]);
  const [reportData, setReportData] = useState([]);
  const [page, setPage] = useState<number>(1);
  const [vendorId, setVendorId] = useState<string>('');
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [circleId, setCircleId] = useState('')

  const [seleceteSource, setseleceteSource] = useState<any>({});
  const [pageNo, setpageNo] = useState<any>(1);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    fetchCircles();
    getReports();
  }, [pageNo]);

  const handlePageClick = (event: any) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const data = event?.selected + 1

    setpageNo(data)

    // if (!userSearch) {
    // dispatch(adminAction.getCurrentBookings(data))

    return () => { }
    // } else {
    //   dispatch(
    //     ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
    //   );

    //   return () => {};
    // }
  };

  const user = JSON.parse(localStorage.getItem('access_admin_token')!).data;

  const handleVendor = async (vData: any) => {
    setVendor(vData);
    setVendorId(vData?.id);
  };

  const fetchCircles = async () => {
    try {
      const response = await axios.get(`${URL.API_BASE_URL + URL.getAllCircles}`);
      if (response?.data?.data) {
        setCircles(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeCircle = async (circleId: string) => {
    // try {
    // setVendorId(circleId);
    setCircleId(circleId)
    // const res = await axios.get(`${URL.API_BASE_URL}${URL.getVendorList}?circle_id=${circleId}`);
    // if (res?.data?.results) {
    //   setVendor(res?.data?.results);
    // }
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const getReports = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("kt-auth-react-v")!);
      const config = {
        headers: {
          Authorization: "Bearer " + token.api_token,
        },
      };

      const query = [
        `approved=accept`,
        `page=${pageNo}`,
        vendorId && `vendor=${vendorId}`,
        // vehicleId && `vehicle_id=${vehicleId}`,
        fromDate && `fromdate=${moment(fromDate).format('YYYY-MM-DD')}`,
        toDate && `todate=${moment(toDate).format('YYYY-MM-DD')}`,
        name && `search=${name}`,
      ].filter(Boolean).join('&');

      const res = await axios.get(`${URL.API_BASE_URL}/api/admins/revenueVendorwise?${query}`, config);


      if (res?.data?.data?.data) {
        setReportData(res?.data?.data?.data);
        // setPage(res?.data?.current_page);
        // setPage(res?.data?.data?.current_page)
        setPageCount(Math.ceil(res?.data?.data?.total / 50))
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const loadOptionsgetSource = async (search: any, loadedOptions: any, { page }: { page: any }) => {
    const token: any = await localStorage.getItem('kt-auth-react-v')

    // roledataUpdate
    const response = await fetch(
      `${URL.API_BASE_URL}${URL.getVendorList}?circle_id=${circleId}&page=${page}&search=${search}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token).api_token}`,
        },
      }
    );

    const responseJSON: PaginatedResponse = await response.json()
    const options: any = responseJSON?.results?.map((data: any) => {
      data.value = data?.id;
      data.label = data?.name;
      return data;
    });
    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    }
  };

  const handleDownload = async () => {
    try {
      const token: any = await localStorage.getItem('kt-auth-react-v')
      const config = {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(token).api_token,
        },
      }
      const query = [
        `approved=accept`,
        vendorId && `vendor=${vendorId}`,
        // vehicleId && `vehicle_id=${vehicleId}`,
        fromDate && `fromdate=${moment(fromDate).format('YYYY-MM-DD')}`,
        toDate && `todate=${moment(toDate).format('YYYY-MM-DD')}`,
        name && `search=${name}`,
      ].filter(Boolean).join('&');

      const res = await axios.get(
        `${URL.API_BASE_URL}${URL.downloadVendorwise}?${query}`,
        config
      )
      console.log(res)
      console.log(res?.data?.data)
      if (res?.data?.data) {
        return res?.data?.data;
      };
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    };
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h3 className="text-gray-600">Revenue Report</h3>
        <p><span className="text-primary">Home</span> / Revenue Report</p>
      </div>
      <div className="bg-transparent shadow m-0 p-3 pt-6 rounded-2xl">
        <div className="d-flex justify-content-between px-5">
          <div className="me-3" style={{ position: 'relative' }}>
            <label><b>Circle</b></label>
            <select
              className="form-control"
              aria-label="Select example"
              name="circle_id"
              onChange={(e:any) => handleChangeCircle(e.target.value)}
            >
              <option value="">Select Circle</option>
              {circles.map((data, i) => (
                <option value={data.id} key={i}>{data.name}</option>
              ))}
            </select>
          </div>
          <div className='me-3 col-2' style={{ position: 'relative' }}>
            <label>
              <b>Vendor</b>
            </label>{' '}
            <br />
            <AsyncPaginate<any, any, any>
              key={circleId}
              value={vendor}
              loadOptions={loadOptionsgetSource}
              onChange={(item:any) => handleVendor(item)}
              additional={{ page: 1, search: "" }}
              styles={{}}
              className='bg-transparent form-control p-0'
            />
          </div>

          <div className="me-3">
            <label><b>Date From:</b></label>
            <input
              className="form-control"
              type="date"
              onChange={(e:any) => setFromDate(e.target.value)}
            />
          </div>
          <div className="me-3">
            <label><b>Date To:</b></label>
            <input
              className="form-control"
              type="date"
              onChange={(e:any) => setToDate(e.target.value)}
            />
          </div>
          <div
            onClick={() => getReports()}
            className="mt-8"
            style={{ backgroundColor: '#52A841', color: 'white', height: '30px', width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
          >
            View
          </div>
        </div>

        {/* Copy CSV PDF EXCEL Search */}
        <div className="d-flex align-items-center justify-content-between gap-4 mt-4 px-5">
          <div className="d-flex gap-1">
            {/* <div className="p-2" style={{ border: '1px solid #000', backgroundColor: '#ADAFAD', cursor: 'pointer' }}
              onClick={async () => {
                const data = await handleDownload();
                if (!data || !Array.isArray(data)) {
                  alert("No data available to copy");
                  return;
                }
                handleCopy(data);
              }}
            >Copy</div > */}
            <div className="p-2" style={{ border: '1px solid #000', backgroundColor: '#ADAFAD', cursor: 'pointer' }}
              onClick={async () => {
                const data = await handleDownload();

                handleCSVNew(data, revenueReportColumns, "Report Revenue Wise", fromDate, toDate)
              }}
            >CSV</div>
            <div className="p-2" style={{ border: '1px solid #000', backgroundColor: '#ADAFAD', cursor: 'pointer' }}
              onClick={async () => {
                const data = await handleDownload();

                handlePDFNew(data, revenueReportColumns, "Report Revenue Wise", fromDate, toDate)
              }}
            >PDF</div>
            <div className="p-2" style={{ border: '1px solid #000', backgroundColor: '#ADAFAD', cursor: 'pointer' }}
              onClick={async () => {
                const data = await handleDownload();

                handleExcelNew(data, revenueReportColumns, "Report Revenue Wise", fromDate, toDate)
              }}
            >EXCEL</div>
          </div>
          <div>
            <input type="search" placeholder="Search by Name" className="form-control" onChange={(e:any) => setName(e.target.value)} />
          </div>
        </div>

        {/* Revenue Table */}
        <div className="mt-9">
          <table className="table" style={{ 'fontSize': '12px', 'fontWeight': '300' }}>
            <thead className="table-light">
              <tr>
                <th>S.NO.</th>
                <th>Vendor</th>
                <th>Tanker Number</th>
                <th>Total Trip</th>
                <th>Distance</th>
              </tr>
            </thead>
            <tbody style={{ 'maxHeight': '60vh', 'overflow': 'auto' }} >
              {
                reportData.length > 0 && reportData.map((item: any, index: any) => {

                  return (
                    <tr key={index}>
                      <td> {(pageNo - 1) * 50 + index + 1} </td>
                      <td>{item?.name}</td>
                      <td>{item?.tankers_number}</td>
                      <td>{item?.bookings_count}</td>
                      {/* <td>{item?.bookings_sum_total_distance}</td> */}
                      <td>{item?.total_distance?.toFixed(2)}</td>
                    </tr>
                  )
                })
              }
            </tbody >

            <tfoot>
              <tr>

                <td><b>Date</b></td>
                <td>
                  {' '}
                  {fromDate ? fromDate : '--'} to &nbsp;{toDate ? toDate : '--'}
                </td>
                {/* <td> 31-05-2024 to 31-05-2024</td> */}
                <td><b>Total</b></td>
                <td className="text-center fs-5">
                  {reportData?.reduce((acc: number, item: { bookings_count?: number }) =>
                    acc + (item?.bookings_count ?? 0), 0)}
                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={5} style={{ 'textAlign': 'center' }}>
                  This is System Generated Report it does not require any signature or stamp.
                </td>
              </tr>
            </tfoot>
          </table>
          {/* <p>Showing 1 to 201 of 201 entries</p> */}
          <div style={{ height: '45px' }}>
            <ReactPaginate
              breakLabel='...'
              nextLabel='next'
              className='dataTables_paginate paging_simple_numbers category'
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              pageCount={pageCount}
              previousLabel='previous'
            // renderOnZeroPageCount={null}
            />
          </div>
        </div>

      </div>
    </div>
  )
}

export default Vef_Revenue