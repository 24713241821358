import { FC, useEffect, useState } from 'react'
import { useLayout } from '../../../_metronic/layout/core'
import { URL } from '../../../redux/common/url'
import clsx from 'clsx'
import axios from 'axios'
import { toast } from 'react-toastify'
import { handleCopy, handleCSV, handleCSVNew, handleExcel, handleExcelNew, handlePDF, handlePDFNew } from './print.js'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { AsyncPaginate } from 'react-select-async-paginate'
import { reportColumns } from '../../constant'




interface Value {
  id: string
  name: string
}

interface Option {
  id: string
}

interface Options {
  value: string
  label: string
};

interface PaginatedResponse {
  results: Options[]
  has_more: boolean
};

const VehicalWise: FC = () => {

  const [vendor, setVendor] = useState([]);
  const [circle_id, setCircle_id] = useState('');
  const [vendor_id, setVendor_id] = useState('');
  const [fromdate, setfromdate] = useState('');
  const [todate, settodate] = useState('');
  const [vehical_id, setVehical_id] = useState('');
  const [vehical, setVehical] = useState([]);
  const [circles, setCircles] = useState<Value[]>([]);
  const [name, setName] = useState<string>('');
  const [reportData, setReportData] = useState<any[]>([]);
  const [approved, setApproved] = useState<Option[]>([]);
  const [pageNo, setpageNo] = useState<any>(1);
  const [pageCount, setPageCount] = useState(0);

  const totalAmount = reportData?.reduce((acc, curr) => acc + curr.amount, 0)

  useEffect(() => {
    fetchCircles();
    getReports();
  }, [pageNo]);


  function checkAllData(e: any) {
    if (!e) {
      setApproved([])
    } else {
      setApproved(reportData.map((data) => data.id))
    }
  };

  const handlePageClick = (event: any) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const data = event?.selected + 1

    setpageNo(data)

    // if (!userSearch) {
    // dispatch(adminAction.getCurrentBookings(data))

    return () => { }
    // } else {
    //   dispatch(
    //     ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
    //   );

    //   return () => {};
    // }
  };

  const getReports = async () => {
    try {
      const token: any = await localStorage.getItem('kt-auth-react-v')
      const config = {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(token).api_token,
        },
      }

      var cms = ''
      if (circle_id) {
        cms = `&circle_id=${circle_id}`
      }
      if (pageNo) {
        cms = cms + `&page=${pageNo}`
      }
      if (vendor_id) {
        cms = cms + `&vendor=${vendor_id}`
      }
      if (vehical_id) {
        cms = cms + `&vehicle=${vehical_id}`
      }

      if (fromdate) {
        cms = cms + `&fromdate=${fromdate}`
      }
      if (todate) {
        cms = cms + `&todate=${todate}`
      }
      if (name) {
        cms = cms + `&search=${name}`
      }
      const res = await axios.get(
        `${URL.API_BASE_URL}${URL.getReports}?approved=pending${cms}`,
        config
      )
      if (res?.data?.data?.data) {
        setReportData(res?.data?.data?.data)
        setPageCount(Math.ceil(res?.data?.data?.total / 50))
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    }
  };

  const all_vehicles = async (e: any) => {
    setVendor_id(e);
    setVehical_id('')
  };

  const fetchCircles = async () => {
    try {
      const response: any = await axios.get(`${URL.API_BASE_URL + URL.getAllCircles}`)
      if (response?.data?.data) {
        setCircles(response?.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
  };

  const fetchgetAllVendor = async (e: any) => {
    try {
      setCircle_id(e)
      const res = await axios.get(`${URL.API_BASE_URL}${URL.getVendorList}?circle_id=${e}`)
      if (res?.data?.results) {
        setVendor(res?.data?.results)
        setVehical_id('')
        setVendor_id('')
      }
    } catch (error) {
      console.log(error)
    }
  };

  const approveBulkReport = async () => {
    try {
      if (approved.length === 0) {
        toast.error('Please select atleast one record')
        return
      }
      const token: any = await localStorage.getItem('kt-auth-react-v')
      const config = {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(token).api_token,
        },
      }
      const res = await axios.post(
        `${URL.API_BASE_URL}/api/admins/approveBulkReport`,
        { ids: approved, approved: 'accept' },
        config
      )
      if (res?.data?.data) {
        getReports()
        toast.success(res?.data?.message)
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    }
  };

  // reject
  const disapproveBulkReport = async () => {
    try {
      if (approved.length === 0) {
        toast.error('Please select atleast one record')
        return
      }
      const token: any = await localStorage.getItem('kt-auth-react-v')
      const config = {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(token).api_token,
        },
      }
      const res = await axios.post(
        `${URL.API_BASE_URL}/api/admins/approveBulkReport`,
        { ids: approved, approved: 'reject' },
        config
      )
      if (res?.data?.data) {
        getReports()
        toast.success(res?.data?.message)
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    }
  };

  const loadOptionsVendor = async (search: any, loadedOptions: any, { page }: { page: any, }) => {
    const token: any = await localStorage.getItem('kt-auth-react-v');

    let url = `${URL.getVendorList}?circle_id=${circle_id}`;

    const response = await fetch(`${URL.API_BASE_URL + url}&page=${page}&search=${search}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token).api_token}`,
        },
      }
    );
    const responseJSON: PaginatedResponse = await response.json();
    const options: any = responseJSON?.results?.map((data: any) => {
      data.value = data?.id;
      data.label = data?.name;
      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    }
  };

  const loadOptionsVehicle = async (search: any, loadedOptions: any, { page }: { page: any }) => {
    const token: any = await localStorage.getItem('kt-auth-react-v');

    let url = `${URL.all_vendors_tanker}?vendor_id=${vendor_id}`;

    const response = await fetch(`${URL.API_BASE_URL + url}&page=${page}&search=${search}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token).api_token}`,
        },
      }
    );
    const responseJSON: PaginatedResponse = await response.json();
    const options: any = responseJSON?.results?.map((data: any) => {
      data.value = data?.vehicle_id;
      data.label = data?.vehicle?.registration_number;
      return data;
    });
    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    }
  };

  const handleDownload = async () => {
    try {
      const token: any = await localStorage.getItem('kt-auth-react-v')
      const config = {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(token).api_token,
        },
      }

      var cms = ''
      if (circle_id) {
        cms = `&circle_id=${circle_id}`
      }
      if (vendor_id) {
        cms = cms + `&vendor=${vendor_id}`
      }
      if (vehical_id) {
        cms = cms + `&vehicle=${vehical_id}`
      }

      if (fromdate) {
        cms = cms + `&fromdate=${fromdate}`
      }
      if (todate) {
        cms = cms + `&todate=${todate}`
      }
      if (name) {
        cms = cms + `&search=${name}`
      }
      const res = await axios.get(
        `${URL.API_BASE_URL}${URL.downloadReports}?approved=pending${cms}`,
        config
      );
      if (res?.data?.data) {
        return res?.data?.data;
      };
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    };
  };

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <h3 className='text-gray-600'>Trip Report Vehicle Wise</h3>
        <p>
          <span className='text-primary'>Home</span> / Trip Report Vehicle Wise
        </p>
      </div>
      <div className={clsx('shadow m-0 p-3 pt-6 rounded-2x')}>

        {/* Filter Options */}
        <div className='d-flex justify-content-between px-5'>
          <div className='me-3' style={{ position: 'relative' }}>
            <label>
              <b>Circle</b>
            </label>
            <select
              className='form-control'
              aria-label='Select example'
              name='circle_id'
              onChange={(e:any) => {
                fetchgetAllVendor(e.target.value)
              }}
            >
              <option value={''}>Select Circle</option>
              {circles?.map((data, i) => {
                return (
                  <option value={data?.id} key={i}>
                    {data?.name}
                  </option>
                )
              })}
            </select>
          </div>

          <div className='me-3 col-2' style={{ position: 'relative' }}>
            <label>
              <b>Vendor</b>
            </label>{' '}
            <br />
            <AsyncPaginate<any, any, any>
              key={circle_id}
              // value={vendor_id}
              loadOptions={loadOptionsVendor}
              onChange={(item:any) => all_vehicles(item?.value)}
              additional={{ page: 1 }}
              styles={{}}
              className='bg-transparent form-control p-0'
            />
          </div>

          <div className='me-3 col-2' style={{ position: 'relative' }}>
            <label>
              <b>Vehicle</b>
            </label>{' '}
            <br />
            <AsyncPaginate<any, any, any>
              key={vendor_id}
              // value={vendor_id}
              loadOptions={loadOptionsVehicle}
              onChange={(e:any) => setVehical_id(e?.vehicle_id)}
              additional={{ page: 1 }}
              styles={{}}
              className='bg-transparent form-control p-0'
            />
          </div>

          <div className='me-3'>
            <label>
              <b>Date From:</b>
            </label>{' '}
            <br />
            <input
              className='form-control'
              type='date'
              onChange={(e:any) => {
                setfromdate(e?.target?.value)
              }}
            />
          </div>
          <div className='me-3'>
            <label>
              <b>Date To:</b>
            </label>{' '}
            <br />
            <input
              className='form-control'
              type='date'
              onChange={(e:any) => {
                settodate(e?.target?.value)
              }}
            />
          </div>
          <div
            className='mt-8'
            style={{
              backgroundColor: '#52A841',
              color: 'white',
              height: '30px',
              width: '250px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => {
              getReports()
            }}
          >
            View
          </div>
        </div>

        <div className='d-flex align-items-center justify-content-end gap-4 px-5'>
          <div className='d-flex content-center justify-content-center gap-3'>
            <input
              onChange={(e:any) => {
                checkAllData(e.target.checked)
              }}
              type='checkbox'
              id='select'
            />{' '}
            <label htmlFor='select'>
              <b>check / uncheck all</b>
            </label>
          </div>

          <div
            onClick={approveBulkReport}
            className='mt-8'
            style={{
              backgroundColor: '#ADAFAD',
              color: 'white',
              height: '30px',
              width: '250px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Approve Selected
          </div>

          <div
            onClick={disapproveBulkReport}
            className='mt-8'
            style={{
              backgroundColor: '#E21965',
              color: 'white',
              height: '30px',
              width: '250px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Disapprove Selected
          </div>
        </div>

        <div className='d-flex justify-content-betq align-items-center gap-4 px-5'>
          <div className='d-flex gap-1'>
            <div
              className='p-2'
              style={{ border: '1px solid #000', backgroundColor: '#ADAFAD' }}
              onClick={async () => {
                const data = await handleDownload();
                handleCSVNew(data, reportColumns, "Report Vehicle Wise", fromdate, todate)
              }}
            >
              CSV
            </div>
            <div
              className='p-2'
              style={{ border: '1px solid #000', backgroundColor: '#ADAFAD' }}
              onClick={async () => {
                const data = await handleDownload();
                handlePDFNew(data, reportColumns, "Report Vehicle Wise", fromdate, todate)
              }}
            >
              PDF
            </div>
            <div
              className='p-2'
              style={{ border: '1px solid #000', backgroundColor: '#ADAFAD' }}
              onClick={async () => {
                const data = await handleDownload();
                handleExcelNew(data, reportColumns, "Report Vehicle Wise", fromdate, todate)
              }}
            >
              EXCEL
            </div>
          </div>

          <div>
            <input
              type='search'
              placeholder='Search wise mobile number'
              className='form-control'
              onChange={(e:any) => setName(e.target.value)}
            />
          </div>
        </div>

        {/* Vehicle table  */}
        <div className='mt-9'>
          <table className='table' style={{ fontSize: '10px', fontWeight: '300' }}>
            <thead className='table-light'>
              <tr>
                <th>S.NO.</th>
                <th>Job Id</th>
                <th>Pickup location</th>
                <th>Drop location</th>
                <th>Pickup Date / time</th>
                <th>End Date / time</th>
                <th>Trip Duration</th>
                <th>Name</th>
                <th>Number</th>
                <th>Tanker Number</th>
                <th>Distance</th>
                <th>Amount</th>
                <th>Remarks</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ maxHeight: '60vh', overflow: 'auto' }}>
              {reportData.length > 0 &&
                reportData.map((item: any, index: any) => (
                  <tr
                    key={index}
                    onClick={() => {
                      approved.includes(item?.id)
                        ? setApproved((prev) =>
                          prev.filter((approvedId) => approvedId !== item?.id)
                        )
                        : setApproved((prev) => [...prev, item?.id])
                    }}
                    className={approved.includes(item?.id) ? 'bg-success text-white' : ''}
                  >
                    <td> {(pageNo - 1) * 50 + index + 1} </td>
                    <td>{item?.id}</td>
                    <td>{item?.source_hydrant_center?.address}</td>
                    <td>{item?.destination?.address}</td>
                    <td>{item?.accepted_time}</td>
                    <td>{item?.complete_time}</td>
                    <td>{item?.trip_duration}</td>

                    <td>{item?.user?.name}</td>
                    <td>{item?.user?.mobile}</td>
                    <td>{item?.vehicle?.registration_number}</td>
                    <td>{item?.total_distance}</td>
                    <td>{item?.amount}</td>
                    <td>{item?.remark}</td>
                    <td>
                      <Link to={`/view_details/${item?.id}/pending`}>
                        <button className='btn-group btn-group-sm bg-primary text-white'>
                          View Details
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>

            <tfoot>
              <tr>
                <td colSpan={6}></td>
                <td>
                  <b>Date</b>
                </td>
                <td>
                  {' '}
                  {fromdate ? fromdate : '--'} to{todate ? todate : '--'}
                </td>
                <td colSpan={2}></td>
                <td>
                  <b>Total</b>
                </td>
                <td colSpan={2} className='text-center fs-5'>
                  {totalAmount}
                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={14} style={{ textAlign: 'center' }}>
                  This is System Generated Report it does not require any signature or stamp.
                </td>
              </tr>
            </tfoot>
          </table>
          {/* <p>Showing 1 to 201 of 201 entries</p>  */}
          <div style={{ height: '45px' }}>
            <ReactPaginate
              breakLabel='...'
              nextLabel='next'
              className='dataTables_paginate paging_simple_numbers category'
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              pageCount={pageCount}
              previousLabel='previous'
            // renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default VehicalWise
