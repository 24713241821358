/* eslint-disable jsx-a11y/anchor-is-valid */
import {StepProps} from '../IAppModels'

const Step3 = ({data, updateData, hasError}: StepProps) => {
  return (
    <>
      {/*begin::Step 3 */}
      <div className='pb-5' data-kt-stepper-element='content'>
        <div className='w-100'>
          {/*begin::Form Group */}

          <div className='mb-10 fv-row'>
            <label className='mb-2 fw-semibold required fs-5'>Database Name</label>

            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='dbname'
              value={data.appDatabase.databaseName}
              onChange={(e:any) =>
                updateData({
                  appDatabase: {
                    databaseName: e.target.value,
                    databaseSolution: data.appDatabase.databaseSolution,
                  },
                })
              }
            />
            {!data.appDatabase.databaseName && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Database name is required
                </div>
              </div>
            )}
          </div>
          {/*end::Form Group */}

          {/*begin::Form Group */}
          <div className='fv-row'>
            <label className='d-flex align-items-center mb-4 fw-semibold fs-5'>
              <span className='required'>Select Database Engine</span>

              <i
                className='ms-2 fas fa-exclamation-circle fs-7'
                data-bs-toggle='tooltip'
                title='Select your app database engine'
              ></i>
            </label>

            {/*begin:Option */}
            <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
              <span className='d-flex align-items-center me-2'>
                <span className='me-6 symbol symbol-50px'>
                  <span className='bg-light-success symbol-label'>
                    <i className='text-success fas fa-database fs-2x'></i>
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6'>MySQL</span>
                  <span className='text-muted fs-7'>Basic MySQL database</span>
                </span>
              </span>

              <span className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='databaseSolution'
                  value='MySQL'
                  checked={data.appDatabase.databaseSolution === 'MySQL'}
                  onChange={() =>
                    updateData({
                      appDatabase: {
                        databaseName: data.appDatabase.databaseName,
                        databaseSolution: 'MySQL',
                      },
                    })
                  }
                />
              </span>
            </label>
            {/*end::Option */}

            {/*begin:Option */}
            <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
              <span className='d-flex align-items-center me-2'>
                <span className='me-6 symbol symbol-50px'>
                  <span className='bg-light-danger symbol-label'>
                    <i className='text-danger fab fa-google fs-2x'></i>
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6'>Firebase</span>
                  <span className='text-muted fs-7'>Google based app data management</span>
                </span>
              </span>

              <span className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='databaseSolution'
                  value='Firebase'
                  checked={data.appDatabase.databaseSolution === 'Firebase'}
                  onChange={() =>
                    updateData({
                      appDatabase: {
                        databaseName: data.appDatabase.databaseName,
                        databaseSolution: 'Firebase',
                      },
                    })
                  }
                />
              </span>
            </label>
            {/*end::Option */}

            {/*begin:Option */}
            <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
              <span className='d-flex align-items-center me-2'>
                <span className='me-6 symbol symbol-50px'>
                  <span className='bg-light-warning symbol-label'>
                    <i className='text-warning fab fa-amazon fs-2x'></i>
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6'>DynamoDB</span>
                  <span className='text-muted fs-7'>Amazon Fast NoSQL Database</span>
                </span>
              </span>

              <span className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='databaseSolution'
                  value='DynamoDB'
                  checked={data.appDatabase.databaseSolution === 'DynamoDB'}
                  onChange={() =>
                    updateData({
                      appDatabase: {
                        databaseName: data.appDatabase.databaseName,
                        databaseSolution: 'DynamoDB',
                      },
                    })
                  }
                />
              </span>
            </label>
            {/*end::Option */}
          </div>
          {/*end::Form Group */}
        </div>
      </div>
      {/*end::Step 3 */}
    </>
  )
}

export {Step3}
