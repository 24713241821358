import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { URL } from '../../../redux/common/url';
import { AsyncPaginate } from 'react-select-async-paginate';
import moment from 'moment';
import axios from 'axios';
import { DatePicker } from 'antd';
import { usePDF } from 'react-to-pdf';

import './table.css'

const { RangePicker } = DatePicker;

interface Option {
    value: string;
    label: string;
}

interface PaginatedResponse {
    results: Option[];
    has_more: boolean;
}

interface Value {
    id: string;
    name: string;
}

const Vef_Invoice = () => {
    const [criId, setcriId] = useState<any>('')

    const [circles, setCircles] = useState<Value[]>([])

    const [managerKey, setManagerKey] = useState<any>(0)
    const [managerKeyAen, setManagerKeyAen] = useState<any>(0)
    const [managerKeyJen, setManagerKeyJen] = useState<any>(0)

    const [seleceteMangerXen, setseleceteMangerXen] = useState<any>({})
    const [seleceteMangerAen, setseleceteMangerAen] = useState<any>({})
    const [seleceteMangerJen, setseleceteMangerJen] = useState<any>({})
    const [seleceteMangerVendor, setseleceteMangerVendor] = useState<any>({})

    const options = {
        filename: 'phed_invoice.pdf',

    };

    const { toPDF, targetRef } = usePDF(options);


    const loadOptionsgetManagerXen = async (search: any, loadedOptions: any, { page }: { page: any }) => {
        const token: any = await localStorage.getItem('kt-auth-react-v')

        // roledataUpdate

        const response = await fetch(
            URL.API_BASE_URL + `/api/admins/get_all_xen?search=${search}&page=${page}&circle_id=${criId}`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token).api_token}`,
                },
            }
        )

        const responseJSON: PaginatedResponse = await response.json()

        const options: any = responseJSON?.results?.map((data: any) => {
            data.value = data?.id
            data.label = data?.name

            return data
        })

        return {
            options: options,
            hasMore: responseJSON.has_more,
            additional: {
                page: page + 1,
            },
        }
    }
    const loadOptionsgetManagerAen = async (search: any, loadedOptions: any, { page }: { page: any }) => {
        const token: any = await localStorage.getItem('kt-auth-react-v')

        // roledataUpdate

        const response = await fetch(
            URL.API_BASE_URL +
            `/api/admins/get_department_child?search=${search}&page=${page}&id=${seleceteMangerXen?.id}&circle_id=${criId}`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token).api_token}`,
                },
            }
        )

        const responseJSON: PaginatedResponse = await response.json()

        const options: any = responseJSON?.results?.map((data: any) => {
            data.value = data?.id
            data.label = data?.name

            return data
        })

        return {
            options: options,
            hasMore: responseJSON.has_more,
            additional: {
                page: page + 1,
            },
        }
    }
    const loadOptionsgetManagerJen = async (search: any, loadedOptions: any, { page }: { page: any }) => {
        const token: any = await localStorage.getItem('kt-auth-react-v')

        // roledataUpdate

        const response = await fetch(
            URL.API_BASE_URL +
            `/api/admins/get_department_child?search=${search}&page=${page}&id=${seleceteMangerAen?.id}&circle_id=${criId}`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token).api_token}`,
                },
            }
        )

        const responseJSON: PaginatedResponse = await response.json()

        const options: any = responseJSON?.results?.map((data: any) => {
            data.value = data?.id
            data.label = data?.name

            return data
        })

        return {
            options: options,
            hasMore: responseJSON.has_more,
            additional: {
                page: page + 1,
            },
        }
    }

    const loadOptionsgetManagerVendor = async (
        search: any,
        loadedOptions: any,
        { page }: { page: any }
    ) => {

        const token: any = await localStorage.getItem('kt-auth-react-v')

        // roledataUpdate

        const response = await fetch(
            URL.API_BASE_URL +

            `/api/getAllVendor?circle_id=${criId}&page=${page}`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token).api_token}`,
                },
            }
        )

        const responseJSON: PaginatedResponse = await response.json()



        const options: any = responseJSON?.results?.map((data: any) => {
            data.value = data?.id
            data.label = data?.user_name

            return data
        })

        return {
            options: options,
            hasMore: responseJSON.has_more,
            additional: {
                page: page + 1,
            },
        }

    }


    const fetchCircles = async () => {
        try {
            const response: any = await axios.get(`${URL.API_BASE_URL + URL.getAllCircles}`);
            if (response?.data?.data) {
                setCircles(response?.data?.data);
            }
        } catch (error) {
            console.log(error);
        }
    };


    useEffect(() => {
        fetchCircles()
    }, [])

   

    return (

        <div>

            <div className='d-flex flex-column flex-wrap justify-content-center me-3 page-title'>

                <h1 className='d-flex flex-column justify-content-center my-0 fw-bold text-dark page-heading fs-3'>
                    Generate Invoice
                </h1>

                <ul className='my-0 breadcrumb-separatorless pt-1 fw-semibold breadcrumb fs-7'>

                    <li className='breadcrumb-item text-muted'>

                        <Link to={'/'} className='text-hover-primary text-muted'>
                            Home
                        </Link>

                    </li>

                    <li className='breadcrumb-item'>
                        <span className='bg-gray-400 w-5px h-2px bullet'></span>
                    </li>

                    <li className='breadcrumb-item text-muted'>Generate Invoice</li>

                </ul>
            </div>
            <div className='mt-5 p-6 rounded card card-flush'>

                <div className='row'>

                    <div className='col-3'>
                        <div className='p-5'>
                            <label className='form-label mb-2 fw-semibold fs-6'>
                                <span className='required'>Circle</span>
                            </label>
                            <select
                                className='bg-transparent form-control'
                                aria-label='Select example'
                                name='circle_id'
                                onChange={(e:any) => {
                                    setcriId(e?.target?.value)
                                }}
                            >
                                <option value={''}>Select Circle</option>
                                {
                                    circles?.map((data, i) => {
                                        return (
                                            <option value={data?.id} key={i}>
                                                {data?.name}
                                            </option>
                                        )

                                    })
                                }

                            </select>
                        </div>
                    </div>


                    <div className='col-3'>
                        <div className='p-5'>

                            <label className='form-label mb-2 fw-semibold fs-6'>
                                <span className='required'>Select Xen</span>
                                <i
                                    className='ms-2 fas fa-exclamation-circle fs-7'
                                    data-bs-toggle='popover'
                                    data-bs-trigger='hover'
                                    data-bs-html='true'
                                    data-bs-content='Permission names is required to be unique.'
                                ></i>
                            </label>
                            <AsyncPaginate<any, any, any>
                                key={managerKey}
                                value={seleceteMangerXen}
                                loadOptions={loadOptionsgetManagerXen}
                                onChange={setseleceteMangerXen}
                                additional={{
                                    page: 1,
                                }}
                                className='bg-transparent form-control'

                            />
                        </div>
                    </div>


                    <div className='col-3'>
                        <div className='p-5'>
                            <label className='form-label mb-2 fw-semibold fs-6'>
                                <span className='required'>Select Aen</span>
                                <i
                                    className='ms-2 fas fa-exclamation-circle fs-7'
                                    data-bs-toggle='popover'
                                    data-bs-trigger='hover'
                                    data-bs-html='true'
                                    data-bs-content='Permission names is required to be unique.'
                                ></i>
                            </label>
                            <AsyncPaginate<any, any, any>
                                key={managerKeyAen}
                                value={seleceteMangerAen}
                                loadOptions={loadOptionsgetManagerAen}
                                onChange={setseleceteMangerAen}
                                additional={{
                                    page: 1,
                                }}
                                className='bg-transparent form-control'
                            />
                        </div>
                    </div>


                    <div className='col-3'>
                        <div className='p-5'>
                            <label className='form-label mb-2 fw-semibold fs-6'>
                                <span className='required'>Select Jen</span>
                                <i
                                    className='ms-2 fas fa-exclamation-circle fs-7'
                                    data-bs-toggle='popover'
                                    data-bs-trigger='hover'
                                    data-bs-html='true'
                                    data-bs-content='Permission names is required to be unique.'
                                ></i>
                            </label>
                            <AsyncPaginate<any, any, any>
                                key={managerKeyJen}
                                value={seleceteMangerJen}
                                loadOptions={loadOptionsgetManagerJen}
                                onChange={setseleceteMangerJen}
                                additional={{
                                    page: 1,
                                }}
                                className='bg-transparent form-control'
                            />
                        </div>
                    </div>

                </div>

                <div className='row'>

                    <div className='col-3'>
                        <div className='p-5'>
                            <label className='form-label mb-2 fw-semibold fs-6'>
                                <span className='required'>Vendor </span>
                            </label>
                            <AsyncPaginate<any, any, any>
                                // key={managerKey}
                                value={seleceteMangerVendor}
                                loadOptions={loadOptionsgetManagerVendor}
                                onChange={setseleceteMangerVendor}
                                additional={{
                                    page: 1,
                                }}
                                className='bg-transparent form-control'
                            />

                        </div>
                    </div>

                    <div className='col-3'>
                        <div className='p-5'>
                            <label className='form-label mb-2 fw-semibold fs-6'>
                                <span className='required'> TP(%) </span>
                            </label>

                            <input type="number" name="tp" className="form-control" id="" />
                        </div>
                    </div>

                    <div className='col-3'>
                        <div className='p-5'>
                            <label className='form-label mb-2 fw-semibold fs-6'>
                                <span className='required'> Period </span>
                            </label>
                            <RangePicker />
                        </div>
                    </div>
                </div>

                <div className='row' style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                    <div className="mt-8 btn btn-success" style={{ 'backgroundColor': 'green', 'color': 'white', 'height': '30px', 'width': '250px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                        Generate
                    </div>

                </div>


            </div>

            <button onClick={() => toPDF()}>Download PDF</button>

            <div className='mt-10 p-6 rounded card card-flush'>
                <div className='body' ref={targetRef}>

                    <div className="header">
                        <div>GSTIN NO: _______________________________</div>
                        <div className="box">
                             Shree Bala Ji Construction (Bapu Nagar)
                        </div>

                        <div>Ref No. _______________________</div>
                        <div>The Executive Engineer</div>

                        <div className="ak">
                            <div>south</div>
                            <div>Dated: {moment().format('DD-MM-YYYY')}</div>

                        </div>
                        <div>BILL NO: PHED-N-20240608- _______</div>
                        <div>Dated: {moment().format('DD-MM-YYYY')}</div>

                        <div className="ak">
                            <div>Order No. ____________________________________</div>
                            <div>Period: 01-May-2024 To 31-May-2024</div>
                        </div>
                        <div style={{marginTop:'10px'}}>Transportation work of drinking water through tanker under the jurisdiction of SE, PHED, City Circle
                            (North), Jaipur</div>
                    </div>

                    <table>
                        <thead>
                            <tr >
                                <th>S.NO</th>
                                <th>DATE</th>
                                <th>TANKER TRAVEL DISTANCE
                                    FROM 0 TO 5 KMS.</th>
                                <th>TANKER TRAVEL DISTANCE
                                    FROM 5 TO 10 KMS.</th>
                                <th>TANKER TRAVEL DISTANCE
                                    FROM ABOVE 10 KMS.</th>
                                <th>TOTAL TRIP</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>2024-06-01</td>
                                <td>10</td>
                                <td>5</td>
                                <td>3</td>
                                <td>18</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>2024-06-02</td>
                                <td>8</td>
                                <td>6</td>
                                <td>2</td>
                                <td>16</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>2024-06-03</td>
                                <td>7</td>
                                <td>4</td>
                                <td>1</td>
                                <td>12</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>2024-06-04</td>
                                <td>9</td>
                                <td>5</td>
                                <td>4</td>
                                <td>18</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>2024-06-05</td>
                                <td>6</td>
                                <td>7</td>
                                <td>3</td>
                                <td>16</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>2024-06-06</td>
                                <td>11</td>
                                <td>3</td>
                                <td>2</td>
                                <td>16</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>2024-06-07</td>
                                <td>5</td>
                                <td>8</td>
                                <td>1</td>
                                <td>14</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>2024-06-08</td>
                                <td>4</td>
                                <td>6</td>
                                <td>5</td>
                                <td>15</td>
                            </tr>
                        </tbody>
                        <tfoot  >
                            <tr className="totals">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Total 0-5 Kms Amount </td>
                                <td>144 X 132.00 = </td>
                                <td> 19,008.00</td>
                            </tr>
                            <tr className="totals">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Total 5-10 Kms Amount</td>
                                <td>18 X 180.00 = </td>
                                <td>3,240.00</td>
                            </tr>
                            <tr className="totals">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Total Above 10 Kms Amount </td>
                                <td> 0 X 244.00 =</td>
                                <td>0.00</td>

                            </tr>
                            <tr className="totals">
                                <td></td>
                                <td></td>
                                <td></td>

                                <td>Trip Total Amount</td>
                                <td> </td>
                                <td> 22,248.00</td>


                            </tr>
                            <tr className="totals">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>TP 0% Above </td>
                                <td></td>
                                <td> 0.00</td>

                            </tr>
                            <tr className="totals">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td> <b>Grand Total (Payable) Amount </b> </td>
                                <td></td>
                                <td> 22,248.00</td>
                            </tr>
                            
                        </tfoot>
                    </table>
                    <p className='text-center'>--- This is a system generated invoice and does not required any signature or stamp ---</p>
                </div>
            </div>
        </div>

    )
}

export default Vef_Invoice